export const dropdownsData = (
    checkboxFiltersRadio,
    setCheckboxFiltersRadio
) => {
    return ([
    {
        type: 'checkbox',
        title: 'Desenvolvimentos',
        id: 'developments',
        elements: [
            {
                text: 'Nakheel',
                id: 'nakheel'
            },
            {
                text: 'Meydan',
                id: 'meydan'
            }
        ],
        stateObject: {
            state: checkboxFiltersRadio,
            setState: setCheckboxFiltersRadio
        }
    },
    {
        type: 'checkbox',
        title: 'Tipo de projeto',
        id: 'project_type',
        elements: [
            {
                text: 'Villa',
                id: 'villas'
            },
            {
                text: 'Apartamentos',
                id: 'apartments'
            },
            {
                text: 'Lotes',
                id: 'plots'
            },
            {
                text: 'Apartamentos e Villas',
                id: 'apartments_villas'
            },
            {
                text: 'Lotes e Villas e Apartamentos',
                id: 'plots_villas_apartments'
            },
            {
                text: 'Mostrar tudo',
                id: '_all_project_type'
            },
        ],
        stateObject: {
            state: checkboxFiltersRadio,
            setState: setCheckboxFiltersRadio
        }
    },
    {
        type: 'checkbox',
        title: 'Infraestrutura',
        id: 'infrastructure',
        elements: [
            {
                text: 'Marcos',
                id: 'landmarks'
            },
            {
                text: 'Transporte (Metro)',
                id: 'transport'
            },
            {
                text: 'Educação',
                id: 'education'
            },
            {
                text: 'Saúde',
                id: 'health'
            },
            {
                text: 'Retalho ou Compras',
                id: 'retail_or_shopping'
            }
        ],
        stateObject: {
            state: checkboxFiltersRadio,
            setState: setCheckboxFiltersRadio
        }
    }
    ])
}