import MarkHospital from '../../../static/images/mark-hospital-small.svg'
import MarkSchool from '../../../static/images/mark-school-small.svg'
import MarkShopping from '../../../static/images/mark-shopping-small.svg'

import MarkTheGardens from '../../../static/images/markers/the-gardens/mark-the-gardens.svg'


import MarkPalmJebelAli from '../../../static/images/markers/palm-jebel-ali/mark-palm-jebel-ali.svg'
import PalmJebelAliPreview1 from '../../../static/images/markers/palm-jebel-ali/preview1.jpg'
import PalmJebelAliPreview2 from '../../../static/images/markers/palm-jebel-ali/preview2.jpg'
import PalmJebelAliPreview3 from '../../../static/images/markers/palm-jebel-ali/preview3.jpg'
import PalmJebelAliPreview4 from '../../../static/images/markers/palm-jebel-ali/preview4.jpg'
import PalmJebelAliPreview5 from '../../../static/images/markers/palm-jebel-ali/preview5.jpg'
import PalmJebelAliPreview6 from '../../../static/images/markers/palm-jebel-ali/preview6.jpg'
import PalmJebelAliPreview7 from '../../../static/images/markers/palm-jebel-ali/preview7.jpg'
import PalmJebelAliPreview8 from '../../../static/images/markers/palm-jebel-ali/preview8.jpg'
import PalmJebelAliPreview9 from '../../../static/images/markers/palm-jebel-ali/preview9.jpg'

import PalmJebelAliGuidelines1 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_01.jpg"
import PalmJebelAliGuidelines2 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_02.jpg"
import PalmJebelAliGuidelines3 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_03.jpg"
import PalmJebelAliGuidelines4 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_04.jpg"
import PalmJebelAliGuidelines5 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_05.jpg"
import PalmJebelAliGuidelines6 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_06.jpg"
import PalmJebelAliGuidelines7 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_07.jpg"
import PalmJebelAliGuidelines8 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_08.jpg"
import PalmJebelAliGuidelines9 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_09.jpg"
import PalmJebelAliGuidelines10 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_10.jpg"
import PalmJebelAliGuidelines11 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_11.jpg"
import PalmJebelAliGuidelines12 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_12.jpg"
import PalmJebelAliGuidelines13 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_13.jpg"
import PalmJebelAliGuidelines14 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_14.jpg"
import PalmJebelAliGuidelines15 from "../../../static/images/files/Palm Jebel Ali/a. Design Guidelines/PJA_DesignGuidelines_Page_15.jpg"

import PalmJebelAliPlotsDCR_1 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_01.jpg"
import PalmJebelAliPlotsDCR_2 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_02.jpg"
import PalmJebelAliPlotsDCR_3 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_03.jpg"
import PalmJebelAliPlotsDCR_4 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_04.jpg"
import PalmJebelAliPlotsDCR_5 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_05.jpg"
import PalmJebelAliPlotsDCR_6 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_06.jpg"
import PalmJebelAliPlotsDCR_7 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_07.jpg"
import PalmJebelAliPlotsDCR_8 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_08.jpg"
import PalmJebelAliPlotsDCR_9 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_09.jpg"
import PalmJebelAliPlotsDCR_10 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_10.jpg"
import PalmJebelAliPlotsDCR_11 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_11.jpg"
import PalmJebelAliPlotsDCR_12 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_12.jpg"
import PalmJebelAliPlotsDCR_13 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_13.jpg"
import PalmJebelAliPlotsDCR_14 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_14.jpg"
import PalmJebelAliPlotsDCR_15 from "../../../static/images/files/Palm Jebel Ali/b. Plots/DCR/Frond K/FROND K DCR_Page_15.jpg"

import PalmJebelAliPlotsPLC_1 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_01.jpg"
import PalmJebelAliPlotsPLC_2 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_02.jpg"
import PalmJebelAliPlotsPLC_3 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_03.jpg"
import PalmJebelAliPlotsPLC_4 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_04.jpg"
import PalmJebelAliPlotsPLC_5 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_05.jpg"
import PalmJebelAliPlotsPLC_6 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_06.jpg"
import PalmJebelAliPlotsPLC_7 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_07.jpg"
import PalmJebelAliPlotsPLC_8 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_08.jpg"
import PalmJebelAliPlotsPLC_9 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_09.jpg"
import PalmJebelAliPlotsPLC_10 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_10.jpg"
import PalmJebelAliPlotsPLC_11 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_11.jpg"
import PalmJebelAliPlotsPLC_12 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_12.jpg"
import PalmJebelAliPlotsPLC_13 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_13.jpg"
import PalmJebelAliPlotsPLC_14 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_14.jpg"
import PalmJebelAliPlotsPLC_15 from "../../../static/images/files/Palm Jebel Ali/b. Plots/PLC/Frond K/PLC Frond K_Page_15.jpg"

import PalmJebelAliMaterialBoardBrochure1 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_01.jpg"
import PalmJebelAliMaterialBoardBrochure2 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_02.jpg"
import PalmJebelAliMaterialBoardBrochure3 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_03.jpg"
import PalmJebelAliMaterialBoardBrochure4 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_04.jpg"
import PalmJebelAliMaterialBoardBrochure5 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_05.jpg"
import PalmJebelAliMaterialBoardBrochure6 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_06.jpg"
import PalmJebelAliMaterialBoardBrochure7 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_07.jpg"
import PalmJebelAliMaterialBoardBrochure8 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_08.jpg"
import PalmJebelAliMaterialBoardBrochure9 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_09.jpg"
import PalmJebelAliMaterialBoardBrochure10 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_10.jpg"
import PalmJebelAliMaterialBoardBrochure11 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_11.jpg"
import PalmJebelAliMaterialBoardBrochure12 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_12.jpg"
import PalmJebelAliMaterialBoardBrochure13 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_13.jpg"
import PalmJebelAliMaterialBoardBrochure14 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_14.jpg"
import PalmJebelAliMaterialBoardBrochure15 from "../../../static/images/files/Palm Jebel Ali/c. Material Boards Brochure/ThePalm_materialbook_strategy_rev09_Page_15.jpg"

import PalmJebelAliBrochure1 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_01.jpg"
import PalmJebelAliBrochure2 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_02.jpg"
import PalmJebelAliBrochure3 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_03.jpg"
import PalmJebelAliBrochure4 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_04.jpg"
import PalmJebelAliBrochure5 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_05.jpg"
import PalmJebelAliBrochure6 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_06.jpg"
import PalmJebelAliBrochure7 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_07.jpg"
import PalmJebelAliBrochure8 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_08.jpg"
import PalmJebelAliBrochure9 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_09.jpg"
import PalmJebelAliBrochure10 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_10.jpg"
import PalmJebelAliBrochure11 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_11.jpg"
import PalmJebelAliBrochure12 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_12.jpg"
import PalmJebelAliBrochure13 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_13.jpg"
import PalmJebelAliBrochure14 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_14.jpg"
import PalmJebelAliBrochure15 from "../../../static/images/files/Palm Jebel Ali/d. Brochure/Beach Villas Acquamarina/ThePalm_book_Beach Villas_Acquamarina_Page_15.jpg"

import PalmJebelAliFloorPlansUKL1 from "../../../static/images/files/Palm Jebel Ali/e. Floorplans/Floorplans Frond IJKL/001 Acquamarina/Artboard 1.jpg"
import PalmJebelAliFloorPlansUKL2 from "../../../static/images/files/Palm Jebel Ali/e. Floorplans/Floorplans Frond IJKL/001 Acquamarina/Artboard 2.jpg"
import PalmJebelAliFloorPlansUKL3 from "../../../static/images/files/Palm Jebel Ali/e. Floorplans/Floorplans Frond IJKL/001 Acquamarina/PJA ALL Floorplans_Page_01.jpg"
import PalmJebelAliFloorPlansUKL4 from "../../../static/images/files/Palm Jebel Ali/e. Floorplans/Floorplans Frond IJKL/002 Azure Blue/Artboard 1.jpg"
import PalmJebelAliFloorPlansUKL5 from "../../../static/images/files/Palm Jebel Ali/e. Floorplans/Floorplans Frond IJKL/002 Azure Blue/Artboard 2.jpg"
import PalmJebelAliFloorPlansUKL6 from "../../../static/images/files/Palm Jebel Ali/e. Floorplans/Floorplans Frond IJKL/002 Azure Blue/PJA ALL Floorplans_Page_02.jpg"

import PalmJebelAliFloorPlansMNOP1 from "../../../static/images/files/Palm Jebel Ali/e. Floorplans/Floorplans Frond MNOP/001 Acquamarina/Artboard 1.jpg"
import PalmJebelAliFloorPlansMNOP2 from "../../../static/images/files/Palm Jebel Ali/e. Floorplans/Floorplans Frond MNOP/001 Acquamarina/Artboard 2.jpg"
import PalmJebelAliFloorPlansMNOP3 from "../../../static/images/files/Palm Jebel Ali/e. Floorplans/Floorplans Frond MNOP/001 Acquamarina/PJA ALL Floorplans_Page_03.jpg"

import PalmJebelAliMasterplan1 from "../../../static/images/files/Palm Jebel Ali/f. Masterplan/PJA Fronds IJKL Masterplan.jpg"
import PalmJebelAliMasterplan2 from "../../../static/images/files/Palm Jebel Ali/f. Masterplan/PJA Fronds MNOP Masterplan.jpg"

import MarkPalmJumeirah from '../../../static/images/markers/palm-jumeirah/mark-palm-jumeirah.svg'

import MarkVeneto from '../../../static/images/markers/veneto/mark-veneto.svg'
import MarkVenetoBlack from '../../../static/images/markers/veneto/mark-veneto-black.svg'
import VenetoPreview1 from '../../../static/images/markers/veneto/home-service-image_large1625984384.jpg'
import VenetoPreview2 from '../../../static/images/markers/veneto/home-service-image_large1625984413.jpg'
import VenetoPreview3 from '../../../static/images/markers/veneto/home-service-image_large1625984429.jpg'
import VenetoPreview4 from '../../../static/images/markers/veneto/home-service-image_large1625984444.jpg'


import MarkDubaiIslands from '../../../static/images/markers/dubai-islands/mark-dubai-islands.svg'

import DubaiIslandsBrochure1 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_01.jpg"
import DubaiIslandsBrochure2 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_02.jpg"
import DubaiIslandsBrochure3 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_03.jpg"
import DubaiIslandsBrochure4 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_04.jpg"
import DubaiIslandsBrochure5 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_05.jpg"
import DubaiIslandsBrochure6 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_06.jpg"
import DubaiIslandsBrochure7 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_07.jpg"
import DubaiIslandsBrochure8 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_08.jpg"
import DubaiIslandsBrochure9 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_09.jpg"
import DubaiIslandsBrochure10 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_10.jpg"
import DubaiIslandsBrochure11 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_11.jpg"
import DubaiIslandsBrochure12 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_12.jpg"
import DubaiIslandsBrochure13 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_13.jpg"
import DubaiIslandsBrochure14 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_14.jpg"
import DubaiIslandsBrochure15 from "../../../static/images/files/Dubai Islands/a. Brochure/Dubai Islands Brochure_Page_15.jpg"

import DubaiIslandsMasterplan1 from "../../../static/images/files/Dubai Islands/b. Masterplan/Dubai Island A_Page_1.jpg"
import DubaiIslandsMasterplan2 from "../../../static/images/files/Dubai Islands/b. Masterplan/Dubai Island A_Page_2.jpg"

import DubaiIslandsPreview1 from '../../../static/images/markers/dubai-islands/nakheel_dubai_islands_deira_properties_for_sale_1.jpg'
import DubaiIslandsPreview2 from '../../../static/images/markers/dubai-islands/nakheel_dubai_islands_deira_properties_for_sale_4.jpg'
import DubaiIslandsVideo from '../../../static/images/markers/dubai-islands/Dubai Islands Video.mp4'


import MarkJebelAliVillage from '../../../static/images/markers/jebel-ali-village/jebel-ali-village.svg'
import JebelAliVillageVideo from '../../../static/images/markers/jebel-ali-village/Jebel Ali Village.mp4'
import JebelAliVillagePreview1 from '../../../static/images/markers/jebel-ali-village/Renders/Community/C1.jpg'
import JebelAliVillagePreview3 from '../../../static/images/markers/jebel-ali-village/Renders/Community/C3.jpg'
import JebelAliVillagePreview4 from '../../../static/images/markers/jebel-ali-village/Renders/Community/C4.jpg'
import JebelAliVillagePreview5 from '../../../static/images/markers/jebel-ali-village/Renders/Community/C5.jpg'
import JebelAliVillagePreview6 from '../../../static/images/markers/jebel-ali-village/Renders/Community/C6.jpg'
import JebelAliVillagePreview7 from '../../../static/images/markers/jebel-ali-village/Renders/Community/C7.jpg'
import JebelAliVillagePreview8 from '../../../static/images/markers/jebel-ali-village/Renders/Community/C8.jpg'
import JebelAliVillagePreview9 from '../../../static/images/markers/jebel-ali-village/Renders/Community/C9.jpg'

import JebelAliVillageBrochure1 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_01.jpg"
import JebelAliVillageBrochure2 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_02.jpg"
import JebelAliVillageBrochure3 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_03.jpg"
import JebelAliVillageBrochure4 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_04.jpg"
import JebelAliVillageBrochure5 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_05.jpg"
import JebelAliVillageBrochure6 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_06.jpg"
import JebelAliVillageBrochure7 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_07.jpg"
import JebelAliVillageBrochure8 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_08.jpg"
import JebelAliVillageBrochure9 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_09.jpg"
import JebelAliVillageBrochure10 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_10.jpg"
import JebelAliVillageBrochure11 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_11.jpg"
import JebelAliVillageBrochure12 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_12.jpg"
import JebelAliVillageBrochure13 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_13.jpg"
import JebelAliVillageBrochure14 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_14.jpg"
import JebelAliVillageBrochure15 from "../../../static/images/files/Jebel Ali Village/a. Brochure/20220315_Jebel Ali Village Brochure_Page_15.jpg"

import JebelAliVillageFactSheet1 from "../../../static/images/files/Jebel Ali Village/b. FactSheet/JebelAliVillage_Factsheet hr_Page_1.jpg"
import JebelAliVillageFactSheet2 from "../../../static/images/files/Jebel Ali Village/b. FactSheet/JebelAliVillage_Factsheet hr_Page_2.jpg"

import JebelAliVillageFloorPlan1 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_01.jpg"
import JebelAliVillageFloorPlan2 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_02.jpg"
import JebelAliVillageFloorPlan3 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_03.jpg"
import JebelAliVillageFloorPlan4 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_04.jpg"
import JebelAliVillageFloorPlan5 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_05.jpg"
import JebelAliVillageFloorPlan6 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_06.jpg"
import JebelAliVillageFloorPlan7 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_07.jpg"
import JebelAliVillageFloorPlan8 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_08.jpg"
import JebelAliVillageFloorPlan9 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_09.jpg"
import JebelAliVillageFloorPlan10 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_10.jpg"
import JebelAliVillageFloorPlan11 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_11.jpg"
import JebelAliVillageFloorPlan12 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_12.jpg"
import JebelAliVillageFloorPlan13 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_13.jpg"
import JebelAliVillageFloorPlan14 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_14.jpg"
import JebelAliVillageFloorPlan15 from "../../../static/images/files/Jebel Ali Village/c. Floorplan/JebelAliVillage_FloorplanBooklet final_Page_15.jpg"

import JebelAliVillageMasterPlan from "../../../static/images/files/Jebel Ali Village/d. MasterPlan/JAV Masterplan full.jpg"
import JebelAliVillagePaymentPlan from "../../../static/images/files/Jebel Ali Village/e. Payment Plan/JAV Payment Plan.jpg"

import MarkJebelAliClub from '../../../static/images/markers/jebel-ali-club/jebel-ali-club.svg'
import MarkJumeirahPark from '../../../static/images/markers/jumeirah-park/jumeirah-park.svg'
import MarkJumeirahHeights from '../../../static/images/markers/jumeirah-heights/jumeirah-heights.svg'
import MarkJumeirahIslands from '../../../static/images/markers/jumeirah-islands/jumeirah-islands.svg'
import MarkAlFurjan from '../../../static/images/markers/al-furjan/al-furjan.svg'
import MarkMuroojAlFurjan from '../../../static/images/markers/murooj-al-furjan/murooj-al-furjan.svg'


import NayaResidenceMark from '../../../static/images/markers/naya-residence/naya-residence.svg'
import NayaResidenceVideo from '../../../static/images/markers/naya-residence/video.mp4'
import NayaResidencePreview1 from '../../../static/images/markers/naya-residence/renders/01.jpg'
import NayaResidencePreview2 from '../../../static/images/markers/naya-residence/renders/02.jpg'
import NayaResidencePreview3 from '../../../static/images/markers/naya-residence/renders/03.jpg'
import NayaResidencePreview4 from '../../../static/images/markers/naya-residence/renders/04.jpg'
import NayaResidencePreview5 from '../../../static/images/markers/naya-residence/renders/05.jpg'
import NayaResidencePreview6 from '../../../static/images/markers/naya-residence/renders/06.jpg'
import NayaResidencePreview7 from '../../../static/images/markers/naya-residence/renders/07.jpg'
import NayaResidencePreview8 from '../../../static/images/markers/naya-residence/renders/08.jpg'
import NayaResidencePreview9 from '../../../static/images/markers/naya-residence/renders/09.jpg'
import NayaResidencePreview10 from '../../../static/images/markers/naya-residence/renders/10.jpg'

import NayaResidenceBrochure1 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-01.jpg"
import NayaResidenceBrochure2 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-02.jpg"
import NayaResidenceBrochure3 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-03.jpg"
import NayaResidenceBrochure4 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-04.jpg"
import NayaResidenceBrochure5 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-05.jpg"
import NayaResidenceBrochure6 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-06.jpg"
import NayaResidenceBrochure7 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-07.jpg"
import NayaResidenceBrochure8 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-08.jpg"
import NayaResidenceBrochure9 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-09.jpg"
import NayaResidenceBrochure10 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-10.jpg"
import NayaResidenceBrochure11 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-11.jpg"
import NayaResidenceBrochure12 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-12.jpg"
import NayaResidenceBrochure13 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-13.jpg"
import NayaResidenceBrochure14 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-14.jpg"
import NayaResidenceBrochure15 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-15.jpg"
import NayaResidenceBrochure16 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-16.jpg"
import NayaResidenceBrochure17 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-17.jpg"
import NayaResidenceBrochure18 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-18.jpg"
import NayaResidenceBrochure19 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-19.jpg"
import NayaResidenceBrochure20 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-20.jpg"
import NayaResidenceBrochure21 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-21.jpg"
import NayaResidenceBrochure22 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-22.jpg"
import NayaResidenceBrochure23 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-23.jpg"
import NayaResidenceBrochure24 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-24.jpg"
import NayaResidenceBrochure25 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-25.jpg"
import NayaResidenceBrochure26 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-26.jpg"
import NayaResidenceBrochure27 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-27.jpg"
import NayaResidenceBrochure28 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-28.jpg"
import NayaResidenceBrochure29 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-29.jpg"
import NayaResidenceBrochure30 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-30.jpg"
import NayaResidenceBrochure31 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-31.jpg"
import NayaResidenceBrochure32 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-32.jpg"
import NayaResidenceBrochure33 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-33.jpg"
import NayaResidenceBrochure34 from "../../../static/images/files/NayaPT/Naya Fullbrochure_pt_PT-34.jpg"

import NayaResidenceFloorPlansNaya1_1 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG//Naya 1/NAYA 1 - Floor Plan_Page_01.jpg"
import NayaResidenceFloorPlansNaya1_2 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 1/NAYA 1 - Floor Plan_Page_02.jpg"
import NayaResidenceFloorPlansNaya1_3 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 1/NAYA 1 - Floor Plan_Page_03.jpg"
import NayaResidenceFloorPlansNaya1_4 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 1/NAYA 1 - Floor Plan_Page_04.jpg"
import NayaResidenceFloorPlansNaya1_5 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 1/NAYA 1 - Floor Plan_Page_05.jpg"
import NayaResidenceFloorPlansNaya1_6 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 1/NAYA 1 - Floor Plan_Page_06.jpg"
import NayaResidenceFloorPlansNaya1_7 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 1/NAYA 1 - Floor Plan_Page_07.jpg"
import NayaResidenceFloorPlansNaya1_8 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 1/NAYA 1 - Floor Plan_Page_08.jpg"
import NayaResidenceFloorPlansNaya1_9 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 1/NAYA 1 - Floor Plan_Page_09.jpg"
import NayaResidenceFloorPlansNaya1_10 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 1/NAYA 1 - Floor Plan_Page_10.jpg"
import NayaResidenceFloorPlansNaya1_11 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 1/NAYA 1 - Floor Plan_Page_11.jpg"
import NayaResidenceFloorPlansNaya1_12 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 1/NAYA 1 - Floor Plan_Page_12.jpg"
import NayaResidenceFloorPlansNaya1_13 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 1/NAYA 1 - Floor Plan_Page_13.jpg"
import NayaResidenceFloorPlansNaya1_14 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 1/NAYA 1 - Floor Plan_Page_14.jpg"
import NayaResidenceFloorPlansNaya1_15 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 1/NAYA 1 - Floor Plan_Page_15.jpg"

import NayaResidenceFloorPlansNaya2_1 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_01.jpg"
import NayaResidenceFloorPlansNaya2_2 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_02.jpg"
import NayaResidenceFloorPlansNaya2_3 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_03.jpg"
import NayaResidenceFloorPlansNaya2_4 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_04.jpg"
import NayaResidenceFloorPlansNaya2_5 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_05.jpg"
import NayaResidenceFloorPlansNaya2_6 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_06.jpg"
import NayaResidenceFloorPlansNaya2_7 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_07.jpg"
import NayaResidenceFloorPlansNaya2_8 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_08.jpg"
import NayaResidenceFloorPlansNaya2_9 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_09.jpg"
import NayaResidenceFloorPlansNaya2_10 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_10.jpg"
import NayaResidenceFloorPlansNaya2_11 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_11.jpg"
import NayaResidenceFloorPlansNaya2_12 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_12.jpg"
import NayaResidenceFloorPlansNaya2_13 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_13.jpg"
import NayaResidenceFloorPlansNaya2_14 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_14.jpg"
import NayaResidenceFloorPlansNaya2_15 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 2/NAYA 2 - Floor Plan_Page_15.jpg"

import NayaResidenceFloorPlansNaya3_1 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_01.jpg"
import NayaResidenceFloorPlansNaya3_2 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_02.jpg"
import NayaResidenceFloorPlansNaya3_3 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_03.jpg"
import NayaResidenceFloorPlansNaya3_4 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_04.jpg"
import NayaResidenceFloorPlansNaya3_5 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_05.jpg"
import NayaResidenceFloorPlansNaya3_6 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_06.jpg"
import NayaResidenceFloorPlansNaya3_7 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_07.jpg"
import NayaResidenceFloorPlansNaya3_8 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_08.jpg"
import NayaResidenceFloorPlansNaya3_9 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_09.jpg"
import NayaResidenceFloorPlansNaya3_10 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_10.jpg"
import NayaResidenceFloorPlansNaya3_11 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_11.jpg"
import NayaResidenceFloorPlansNaya3_12 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_12.jpg"
import NayaResidenceFloorPlansNaya3_13 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_13.jpg"
import NayaResidenceFloorPlansNaya3_14 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_14.jpg"
import NayaResidenceFloorPlansNaya3_15 from "../../../static/images/markers/naya-residence/Naya Floorplans JPG/Naya 3/NAYA 3 - Floor Plan_Page_15.jpg"

import NayaResidenceFloorPlansNayaLagoonVillas1 from "../../../static/images/markers/naya-residence//Naya Floorplans JPG/Naya Lagoon Villas/NAYA Lagoon Villas - Floor Plan_Page_01.jpg"
import NayaResidenceFloorPlansNayaLagoonVillas2 from "../../../static/images/markers/naya-residence//Naya Floorplans JPG/Naya Lagoon Villas/NAYA Lagoon Villas - Floor Plan_Page_02.jpg"
import NayaResidenceFloorPlansNayaLagoonVillas3 from "../../../static/images/markers/naya-residence//Naya Floorplans JPG/Naya Lagoon Villas/NAYA Lagoon Villas - Floor Plan_Page_03.jpg"
import NayaResidenceFloorPlansNayaLagoonVillas4 from "../../../static/images/markers/naya-residence//Naya Floorplans JPG/Naya Lagoon Villas/NAYA Lagoon Villas - Floor Plan_Page_04.jpg"
import NayaResidenceFloorPlansNayaLagoonVillas5 from "../../../static/images/markers/naya-residence//Naya Floorplans JPG/Naya Lagoon Villas/NAYA Lagoon Villas - Floor Plan_Page_05.jpg"
import NayaResidenceFloorPlansNayaLagoonVillas6 from "../../../static/images/markers/naya-residence//Naya Floorplans JPG/Naya Lagoon Villas/NAYA Lagoon Villas - Floor Plan_Page_06.jpg"
import NayaResidenceFloorPlansNayaLagoonVillas7 from "../../../static/images/markers/naya-residence//Naya Floorplans JPG/Naya Lagoon Villas/NAYA Lagoon Villas - Floor Plan_Page_07.jpg"
import NayaResidenceFloorPlansNayaLagoonVillas8 from "../../../static/images/markers/naya-residence//Naya Floorplans JPG/Naya Lagoon Villas/NAYA Lagoon Villas - Floor Plan_Page_08.jpg"
import NayaResidenceFloorPlansNayaLagoonVillas9 from "../../../static/images/markers/naya-residence//Naya Floorplans JPG/Naya Lagoon Villas/NAYA Lagoon Villas - Floor Plan_Page_09.jpg"
import NayaResidenceFloorPlansNayaLagoonVillas10 from "../../../static/images/markers/naya-residence//Naya Floorplans JPG/Naya Lagoon Villas/NAYA Lagoon Villas - Floor Plan_Page_10.jpg"
import NayaResidenceFloorPlansNayaLagoonVillas11 from "../../../static/images/markers/naya-residence//Naya Floorplans JPG/Naya Lagoon Villas/NAYA Lagoon Villas - Floor Plan_Page_11.jpg"


import MarkTilalAlFurjan from '../../../static/images/markers/tilal-al-furjan/tilal-al-furjan.svg'
import TilalAlFurjanVideo from '../../../static/images/markers/tilal-al-furjan/Video.mp4'
import TilalAlFurjanPreview1 from '../../../static/images/markers/tilal-al-furjan/Renders/4B-A_Back_HC_RT5.jpg'
import TilalAlFurjanPreview2 from '../../../static/images/markers/tilal-al-furjan/Renders/4B-B_Back_HC_RT5.jpg'
import TilalAlFurjanPreview3 from '../../../static/images/markers/tilal-al-furjan/Renders/5B-B_Front_HC_RT4.jpg'
import TilalAlFurjanPreview4 from '../../../static/images/markers/tilal-al-furjan/Renders/5B-C_Front_HC_RT4.jpg'
import TilalAlFurjanPreview5 from '../../../static/images/markers/tilal-al-furjan/Renders/LIVING_4B-F_HC_RT4.jpg'

import TilalAlFurjanBrochure1 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_01.jpg"
import TilalAlFurjanBrochure2 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_02.jpg"
import TilalAlFurjanBrochure3 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_03.jpg"
import TilalAlFurjanBrochure4 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_04.jpg"
import TilalAlFurjanBrochure5 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_05.jpg"
import TilalAlFurjanBrochure6 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_06.jpg"
import TilalAlFurjanBrochure7 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_07.jpg"
import TilalAlFurjanBrochure8 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_08.jpg"
import TilalAlFurjanBrochure9 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_09.jpg"
import TilalAlFurjanBrochure10 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_10.jpg"
import TilalAlFurjanBrochure11 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_11.jpg"
import TilalAlFurjanBrochure12 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_12.jpg"
import TilalAlFurjanBrochure13 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_13.jpg"
import TilalAlFurjanBrochure14 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_14.jpg"
import TilalAlFurjanBrochure15 from "../../../static/images/files/Tilal Al Furjan/a. Brochure/Tilal Al Furjan Floor Plan Brochure_Page_15.jpg"

import TilalAlFurjanFactSheet1 from "../../../static/images/files/Tilal Al Furjan/b. FactSheet/Fact Sheet_Page_1.jpg"
import TilalAlFurjanFactSheet2 from "../../../static/images/files/Tilal Al Furjan/b. FactSheet/Fact Sheet_Page_2.jpg"
import TilalAlFurjanFactSheet3 from "../../../static/images/files/Tilal Al Furjan/b. FactSheet/Fact Sheet_Page_3.jpg"

import MarkMasakinAlFurjan from '../../../static/images/markers/masakin-al-furjan/masakin-al-furjan.svg'
import MarkDiscoveryGardens from '../../../static/images/markers/discovery-gardens/discovery-gardens.svg'
import MarkJumeirahVillageTriangle from '../../../static/images/markers/jumeirah-village-triangle/jumeirah-village-triangle.svg'
import MarkJumeirahVillageCircle from '../../../static/images/markers/jumeirah-village-circle/jumeirah-village-circle.svg'


import LagoonViewsMark from '../../../static/images/markers/lagoon-views/lagoon-views.svg'
import LagoonViewsVideo from '../../../static/images/markers/lagoon-views/LV_Video_VO_Final_EN.mp4'
import LagoonViewsPreview1 from '../../../static/images/markers/lagoon-views/Renders/preview1.jpg'
import LagoonViewsPreview2 from '../../../static/images/markers/lagoon-views/Renders/preview2.jpg'
import LagoonViewsPreview3 from '../../../static/images/markers/lagoon-views/Renders/preview3.jpg'
import LagoonViewsPreview4 from '../../../static/images/markers/lagoon-views/Renders/preview4.jpg'
import LagoonViewsPreview5 from '../../../static/images/markers/lagoon-views/Renders/preview5.jpg'
import LagoonViewsPreview6 from '../../../static/images/markers/lagoon-views/Renders/preview6.jpg'

import LagoonViewsMasterplan from "../../../static/images/files/Lagoon Views/a. Masterplan/Lagoon Views at District One - Masterplan.jpg"

import LagoonViewsBrochure1 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_01.jpg"
import LagoonViewsBrochure2 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_02.jpg"
import LagoonViewsBrochure3 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_03.jpg"
import LagoonViewsBrochure4 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_04.jpg"
import LagoonViewsBrochure5 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_05.jpg"
import LagoonViewsBrochure6 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_06.jpg"
import LagoonViewsBrochure7 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_07.jpg"
import LagoonViewsBrochure8 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_08.jpg"
import LagoonViewsBrochure9 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_09.jpg"
import LagoonViewsBrochure10 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_10.jpg"
import LagoonViewsBrochure11 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_11.jpg"
import LagoonViewsBrochure12 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_12.jpg"
import LagoonViewsBrochure13 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_13.jpg"
import LagoonViewsBrochure14 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_14.jpg"
import LagoonViewsBrochure15 from "../../../static/images/files/Lagoon Views/b. Brochure/Lagoon Views at District One - Brochure_Page_15.jpg"

import LagoonViewsFloorplan1 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_01.jpg"
import LagoonViewsFloorplan2 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_02.jpg"
import LagoonViewsFloorplan3 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_03.jpg"
import LagoonViewsFloorplan4 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_04.jpg"
import LagoonViewsFloorplan5 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_05.jpg"
import LagoonViewsFloorplan6 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_06.jpg"
import LagoonViewsFloorplan7 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_07.jpg"
import LagoonViewsFloorplan8 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_08.jpg"
import LagoonViewsFloorplan9 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_09.jpg"
import LagoonViewsFloorplan10 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_10.jpg"
import LagoonViewsFloorplan11 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_11.jpg"
import LagoonViewsFloorplan12 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_12.jpg"
import LagoonViewsFloorplan13 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_13.jpg"
import LagoonViewsFloorplan14 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_14.jpg"
import LagoonViewsFloorplan15 from "../../../static/images/files/Lagoon Views/c. Floorplan/Lagoon Views at District One - Floor Plans_Page_15.jpg"

import LagoonViewsPaymentPlan from "../../../static/images/files/Lagoon Views/d. Payment Plan/Lagoon Views at District One - Payment Plan.jpg"


import GreenwoodMark from '../../../static/images/markers/greenwood/greenwood.svg'
import Greenwood1 from '../../../static/images/markers/greenwood/01.jpg'
import Greenwood2 from '../../../static/images/markers/greenwood/02.jpg'
import Greenwood3 from '../../../static/images/markers/greenwood/03.jpg'
import Greenwood4 from '../../../static/images/markers/greenwood/C7.jpg'
import GreenwoodVideo from '../../../static/images/markers/greenwood/Greenwood_4.mp4'

import GreenwoodBrochure1 from "../../../static/images/files/Greenwood/a. Brochure/GREENWOOD_Brochure_Page_01.jpg"
import GreenwoodBrochure2 from "../../../static/images/files/Greenwood/a. Brochure/GREENWOOD_Brochure_Page_02.jpg"
import GreenwoodBrochure3 from "../../../static/images/files/Greenwood/a. Brochure/GREENWOOD_Brochure_Page_03.jpg"
import GreenwoodBrochure4 from "../../../static/images/files/Greenwood/a. Brochure/GREENWOOD_Brochure_Page_04.jpg"
import GreenwoodBrochure5 from "../../../static/images/files/Greenwood/a. Brochure/GREENWOOD_Brochure_Page_05.jpg"
import GreenwoodBrochure6 from "../../../static/images/files/Greenwood/a. Brochure/GREENWOOD_Brochure_Page_06.jpg"
import GreenwoodBrochure7 from "../../../static/images/files/Greenwood/a. Brochure/GREENWOOD_Brochure_Page_07.jpg"
import GreenwoodBrochure8 from "../../../static/images/files/Greenwood/a. Brochure/GREENWOOD_Brochure_Page_08.jpg"
import GreenwoodBrochure9 from "../../../static/images/files/Greenwood/a. Brochure/GREENWOOD_Brochure_Page_09.jpg"
import GreenwoodBrochure10 from "../../../static/images/files/Greenwood/a. Brochure/GREENWOOD_Brochure_Page_10.jpg"
import GreenwoodBrochure11 from "../../../static/images/files/Greenwood/a. Brochure/GREENWOOD_Brochure_Page_11.jpg"
import GreenwoodBrochure12 from "../../../static/images/files/Greenwood/a. Brochure/GREENWOOD_Brochure_Page_12.jpg"
import GreenwoodBrochure13 from "../../../static/images/files/Greenwood/a. Brochure/GREENWOOD_Brochure_Page_13.jpg"
import GreenwoodBrochure14 from "../../../static/images/files/Greenwood/a. Brochure/GREENWOOD_Brochure_Page_14.jpg"
import GreenwoodBrochure15 from "../../../static/images/files/Greenwood/a. Brochure/GREENWOOD_Brochure_Page_15.jpg"

import GreenwoodMasterplan from "../../../static/images/files/Greenwood/b. Masterplan/greenwood masterplan v3.jpg"


import RixosMark from '../../../static/images/markers/rixos/rixos.svg'
import Rixos1 from '../../../static/images/markers/rixos/01-8_OP.jpg'
import Rixos2 from '../../../static/images/markers/rixos/Exterioir-2_OP.jpg'
import Rixos3 from '../../../static/images/markers/rixos/Interior-Balcony_OP.jpg'
import Rixos4 from '../../../static/images/markers/rixos/Living-Room_OP.jpg'
import Rixos5 from '../../../static/images/markers/rixos/Living-Room-2_OP.jpg'
import RixosVideo from '../../../static/images/markers/rixos/Rixos Video.mp4'

import RixosPaymentPlan from '../../../static/images/files/Rixos/d. Payment Plan/Rixos Payment Plan.jpg'

import RixosFactSheet1 from '../../../static/images/files/Rixos/c. Factsheet/Rixos_FactSheet_Page_01.jpg'
import RixosFactSheet2 from '../../../static/images/files/Rixos/c. Factsheet/Rixos_FactSheet_Page_02.jpg'
import RixosFactSheet3 from '../../../static/images/files/Rixos/c. Factsheet/Rixos_FactSheet_Page_03.jpg'
import RixosFactSheet4 from '../../../static/images/files/Rixos/c. Factsheet/Rixos_FactSheet_Page_04.jpg'
import RixosFactSheet5 from '../../../static/images/files/Rixos/c. Factsheet/Rixos_FactSheet_Page_05.jpg'
import RixosFactSheet6 from '../../../static/images/files/Rixos/c. Factsheet/Rixos_FactSheet_Page_06.jpg'
import RixosFactSheet7 from '../../../static/images/files/Rixos/c. Factsheet/Rixos_FactSheet_Page_07.jpg'
import RixosFactSheet8 from '../../../static/images/files/Rixos/c. Factsheet/Rixos_FactSheet_Page_08.jpg'
import RixosFactSheet9 from '../../../static/images/files/Rixos/c. Factsheet/Rixos_FactSheet_Page_09.jpg'
import RixosFactSheet10 from '../../../static/images/files/Rixos/c. Factsheet/Rixos_FactSheet_Page_10.jpg'
import RixosFactSheet11 from '../../../static/images/files/Rixos/c. Factsheet/Rixos_FactSheet_Page_11.jpg'
import RixosFactSheet12 from '../../../static/images/files/Rixos/c. Factsheet/Rixos_FactSheet_Page_12.jpg'
import RixosFactSheet13 from '../../../static/images/files/Rixos/c. Factsheet/Rixos_FactSheet_Page_13.jpg'
import RixosFactSheet14 from '../../../static/images/files/Rixos/c. Factsheet/Rixos_FactSheet_Page_14.jpg'
import RixosFactSheet15 from '../../../static/images/files/Rixos/c. Factsheet/Rixos_FactSheet_Page_15.jpg'

import RixosBrochure1 from "../../../static/images/files/Rixos/a. Brochure/Rixos Full Brochure_Page_01.jpg"
import RixosBrochure2 from "../../../static/images/files/Rixos/a. Brochure/Rixos Full Brochure_Page_02.jpg"
import RixosBrochure3 from "../../../static/images/files/Rixos/a. Brochure/Rixos Full Brochure_Page_03.jpg"
import RixosBrochure4 from "../../../static/images/files/Rixos/a. Brochure/Rixos Full Brochure_Page_04.jpg"
import RixosBrochure5 from "../../../static/images/files/Rixos/a. Brochure/Rixos Full Brochure_Page_05.jpg"
import RixosBrochure6 from "../../../static/images/files/Rixos/a. Brochure/Rixos Full Brochure_Page_06.jpg"
import RixosBrochure7 from "../../../static/images/files/Rixos/a. Brochure/Rixos Full Brochure_Page_07.jpg"
import RixosBrochure8 from "../../../static/images/files/Rixos/a. Brochure/Rixos Full Brochure_Page_08.jpg"
import RixosBrochure9 from "../../../static/images/files/Rixos/a. Brochure/Rixos Full Brochure_Page_09.jpg"
import RixosBrochure10 from "../../../static/images/files/Rixos/a. Brochure/Rixos Full Brochure_Page_10.jpg"
import RixosBrochure11 from "../../../static/images/files/Rixos/a. Brochure/Rixos Full Brochure_Page_11.jpg"
import RixosBrochure12 from "../../../static/images/files/Rixos/a. Brochure/Rixos Full Brochure_Page_12.jpg"
import RixosBrochure13 from "../../../static/images/files/Rixos/a. Brochure/Rixos Full Brochure_Page_13.jpg"
import RixosBrochure14 from "../../../static/images/files/Rixos/a. Brochure/Rixos Full Brochure_Page_14.jpg"
import RixosBrochure15 from "../../../static/images/files/Rixos/a. Brochure/Rixos Full Brochure_Page_15.jpg"

import RixosFloorPlansA1_1 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A1/Rixos Floor Plans - A1_Page_01.jpg"
import RixosFloorPlansA1_2 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A1/Rixos Floor Plans - A1_Page_02.jpg"
import RixosFloorPlansA1_3 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A1/Rixos Floor Plans - A1_Page_03.jpg"
import RixosFloorPlansA1_4 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A1/Rixos Floor Plans - A1_Page_04.jpg"
import RixosFloorPlansA1_5 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A1/Rixos Floor Plans - A1_Page_05.jpg"
import RixosFloorPlansA1_6 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A1/Rixos Floor Plans - A1_Page_06.jpg"
import RixosFloorPlansA1_7 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A1/Rixos Floor Plans - A1_Page_07.jpg"
import RixosFloorPlansA1_8 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A1/Rixos Floor Plans - A1_Page_08.jpg"
import RixosFloorPlansA1_9 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A1/Rixos Floor Plans - A1_Page_09.jpg"
import RixosFloorPlansA1_10 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A1/Rixos Floor Plans - A1_Page_10.jpg"
import RixosFloorPlansA1_11 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A1/Rixos Floor Plans - A1_Page_11.jpg"
import RixosFloorPlansA1_12 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A1/Rixos Floor Plans - A1_Page_12.jpg"
import RixosFloorPlansA1_13 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A1/Rixos Floor Plans - A1_Page_13.jpg"
import RixosFloorPlansA1_14 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A1/Rixos Floor Plans - A1_Page_14.jpg"
import RixosFloorPlansA1_15 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A1/Rixos Floor Plans - A1_Page_15.jpg"

import RixosFloorPlansA2_1 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A2/Rixos Floor Plans - A2_Page_01.jpg"
import RixosFloorPlansA2_2 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A2/Rixos Floor Plans - A2_Page_02.jpg"
import RixosFloorPlansA2_3 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A2/Rixos Floor Plans - A2_Page_03.jpg"
import RixosFloorPlansA2_4 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A2/Rixos Floor Plans - A2_Page_04.jpg"
import RixosFloorPlansA2_5 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A2/Rixos Floor Plans - A2_Page_05.jpg"
import RixosFloorPlansA2_6 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A2/Rixos Floor Plans - A2_Page_06.jpg"
import RixosFloorPlansA2_7 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A2/Rixos Floor Plans - A2_Page_07.jpg"
import RixosFloorPlansA2_8 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A2/Rixos Floor Plans - A2_Page_08.jpg"
import RixosFloorPlansA2_9 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A2/Rixos Floor Plans - A2_Page_09.jpg"
import RixosFloorPlansA2_10 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A2/Rixos Floor Plans - A2_Page_10.jpg"
import RixosFloorPlansA2_11 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A2/Rixos Floor Plans - A2_Page_11.jpg"
import RixosFloorPlansA2_12 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A2/Rixos Floor Plans - A2_Page_12.jpg"
import RixosFloorPlansA2_13 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A2/Rixos Floor Plans - A2_Page_13.jpg"
import RixosFloorPlansA2_14 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A2/Rixos Floor Plans - A2_Page_14.jpg"
import RixosFloorPlansA2_15 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A2/Rixos Floor Plans - A2_Page_15.jpg"

import RixosFloorPlansA3_1 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A3/Rixos Floor Plans - A3_Page_001.jpg"
import RixosFloorPlansA3_2 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A3/Rixos Floor Plans - A3_Page_002.jpg"
import RixosFloorPlansA3_3 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A3/Rixos Floor Plans - A3_Page_003.jpg"
import RixosFloorPlansA3_4 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A3/Rixos Floor Plans - A3_Page_004.jpg"
import RixosFloorPlansA3_5 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A3/Rixos Floor Plans - A3_Page_005.jpg"
import RixosFloorPlansA3_6 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A3/Rixos Floor Plans - A3_Page_006.jpg"
import RixosFloorPlansA3_7 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A3/Rixos Floor Plans - A3_Page_007.jpg"
import RixosFloorPlansA3_8 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A3/Rixos Floor Plans - A3_Page_008.jpg"
import RixosFloorPlansA3_9 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A3/Rixos Floor Plans - A3_Page_009.jpg"
import RixosFloorPlansA3_10 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A3/Rixos Floor Plans - A3_Page_010.jpg"
import RixosFloorPlansA3_11 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A3/Rixos Floor Plans - A3_Page_011.jpg"
import RixosFloorPlansA3_12 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A3/Rixos Floor Plans - A3_Page_012.jpg"
import RixosFloorPlansA3_13 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A3/Rixos Floor Plans - A3_Page_013.jpg"
import RixosFloorPlansA3_14 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A3/Rixos Floor Plans - A3_Page_014.jpg"
import RixosFloorPlansA3_15 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A3/Rixos Floor Plans - A3_Page_015.jpg"

import RixosFloorPlansA4_1 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A4/Rixos Floor Plans - A4_Page_01.jpg"
import RixosFloorPlansA4_2 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A4/Rixos Floor Plans - A4_Page_02.jpg"
import RixosFloorPlansA4_3 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A4/Rixos Floor Plans - A4_Page_03.jpg"
import RixosFloorPlansA4_4 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A4/Rixos Floor Plans - A4_Page_04.jpg"
import RixosFloorPlansA4_5 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A4/Rixos Floor Plans - A4_Page_05.jpg"
import RixosFloorPlansA4_6 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A4/Rixos Floor Plans - A4_Page_06.jpg"
import RixosFloorPlansA4_7 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A4/Rixos Floor Plans - A4_Page_07.jpg"
import RixosFloorPlansA4_8 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A4/Rixos Floor Plans - A4_Page_08.jpg"
import RixosFloorPlansA4_9 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A4/Rixos Floor Plans - A4_Page_09.jpg"
import RixosFloorPlansA4_10 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A4/Rixos Floor Plans - A4_Page_10.jpg"
import RixosFloorPlansA4_11 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A4/Rixos Floor Plans - A4_Page_11.jpg"
import RixosFloorPlansA4_12 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A4/Rixos Floor Plans - A4_Page_12.jpg"
import RixosFloorPlansA4_13 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A4/Rixos Floor Plans - A4_Page_13.jpg"
import RixosFloorPlansA4_14 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A4/Rixos Floor Plans - A4_Page_14.jpg"
import RixosFloorPlansA4_15 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - A4/Rixos Floor Plans - A4_Page_15.jpg"

import RixosFloorPlansBeachHouses1 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - Beach Houses/Rixos Floor Plans - Beach Houses_Page_01.jpg"
import RixosFloorPlansBeachHouses2 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - Beach Houses/Rixos Floor Plans - Beach Houses_Page_02.jpg"
import RixosFloorPlansBeachHouses3 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - Beach Houses/Rixos Floor Plans - Beach Houses_Page_03.jpg"
import RixosFloorPlansBeachHouses4 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - Beach Houses/Rixos Floor Plans - Beach Houses_Page_04.jpg"
import RixosFloorPlansBeachHouses5 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - Beach Houses/Rixos Floor Plans - Beach Houses_Page_05.jpg"
import RixosFloorPlansBeachHouses6 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - Beach Houses/Rixos Floor Plans - Beach Houses_Page_06.jpg"
import RixosFloorPlansBeachHouses7 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - Beach Houses/Rixos Floor Plans - Beach Houses_Page_07.jpg"
import RixosFloorPlansBeachHouses8 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - Beach Houses/Rixos Floor Plans - Beach Houses_Page_08.jpg"
import RixosFloorPlansBeachHouses9 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - Beach Houses/Rixos Floor Plans - Beach Houses_Page_09.jpg"
import RixosFloorPlansBeachHouses10 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - Beach Houses/Rixos Floor Plans - Beach Houses_Page_10.jpg"
import RixosFloorPlansBeachHouses11 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - Beach Houses/Rixos Floor Plans - Beach Houses_Page_11.jpg"
import RixosFloorPlansBeachHouses12 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - Beach Houses/Rixos Floor Plans - Beach Houses_Page_12.jpg"
import RixosFloorPlansBeachHouses13 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - Beach Houses/Rixos Floor Plans - Beach Houses_Page_13.jpg"
import RixosFloorPlansBeachHouses14 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - Beach Houses/Rixos Floor Plans - Beach Houses_Page_14.jpg"
import RixosFloorPlansBeachHouses15 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floor Plans - Beach Houses/Rixos Floor Plans - Beach Houses_Page_15.jpg"

import RixosFloorPlansVillas1 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floorplans - Villas/Rixos Floorplans - Villas_Page_01.jpg"
import RixosFloorPlansVillas2 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floorplans - Villas/Rixos Floorplans - Villas_Page_02.jpg"
import RixosFloorPlansVillas3 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floorplans - Villas/Rixos Floorplans - Villas_Page_03.jpg"
import RixosFloorPlansVillas4 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floorplans - Villas/Rixos Floorplans - Villas_Page_04.jpg"
import RixosFloorPlansVillas5 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floorplans - Villas/Rixos Floorplans - Villas_Page_05.jpg"
import RixosFloorPlansVillas6 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floorplans - Villas/Rixos Floorplans - Villas_Page_06.jpg"
import RixosFloorPlansVillas7 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floorplans - Villas/Rixos Floorplans - Villas_Page_07.jpg"
import RixosFloorPlansVillas8 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floorplans - Villas/Rixos Floorplans - Villas_Page_08.jpg"
import RixosFloorPlansVillas9 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floorplans - Villas/Rixos Floorplans - Villas_Page_09.jpg"
import RixosFloorPlansVillas10 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floorplans - Villas/Rixos Floorplans - Villas_Page_10.jpg"
import RixosFloorPlansVillas11 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floorplans - Villas/Rixos Floorplans - Villas_Page_11.jpg"
import RixosFloorPlansVillas12 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floorplans - Villas/Rixos Floorplans - Villas_Page_12.jpg"
import RixosFloorPlansVillas13 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floorplans - Villas/Rixos Floorplans - Villas_Page_13.jpg"
import RixosFloorPlansVillas14 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floorplans - Villas/Rixos Floorplans - Villas_Page_14.jpg"
import RixosFloorPlansVillas15 from "../../../static/images/files/Rixos/b. Floorplans/Rixos Floorplans - Villas/Rixos Floorplans - Villas_Page_15.jpg"

import DistrictOneMark from '../../../static/images/markers/district-one-west-1/district-one-west-1.svg'
import DistrictOne1 from '../../../static/images/markers/district-one-west-1/2023-09-18-97.jpg'
import DistrictOne2 from '../../../static/images/markers/district-one-west-1/2023-09-18-87.jpg'
import DistrictOne3 from '../../../static/images/markers/district-one-west-1/2023-09-18-81.jpg'
import DistrictOne4 from '../../../static/images/markers/district-one-west-1/2023-09-18-73.jpg'
import DistrictOneVideo from '../../../static/images/markers/district-one-west-1/d1west_long_shortfilm_2_rev03.mp4'
import DistrictOneMasterplan from '../../../static/images/files/D1 West/c. Masterplan/D1 West Masterplan.jpg'

import DistrictOneFloorPlans1 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_01.jpg"
import DistrictOneFloorPlans2 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_02.jpg"
import DistrictOneFloorPlans3 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_03.jpg"
import DistrictOneFloorPlans4 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_04.jpg"
import DistrictOneFloorPlans5 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_05.jpg"
import DistrictOneFloorPlans6 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_06.jpg"
import DistrictOneFloorPlans7 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_07.jpg"
import DistrictOneFloorPlans8 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_08.jpg"
import DistrictOneFloorPlans9 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_09.jpg"
import DistrictOneFloorPlans10 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_10.jpg"
import DistrictOneFloorPlans11 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_11.jpg"
import DistrictOneFloorPlans12 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_12.jpg"
import DistrictOneFloorPlans13 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_13.jpg"
import DistrictOneFloorPlans14 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_14.jpg"
import DistrictOneFloorPlans15 from "../../../static/images/files/D1 West/a. FloorPlans/D1 West Floor Plans_Page_15.jpg"

import DistrictOneBrochure1 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_01.jpg"
import DistrictOneBrochure2 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_02.jpg"
import DistrictOneBrochure3 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_03.jpg"
import DistrictOneBrochure4 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_04.jpg"
import DistrictOneBrochure5 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_05.jpg"
import DistrictOneBrochure6 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_06.jpg"
import DistrictOneBrochure7 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_07.jpg"
import DistrictOneBrochure8 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_08.jpg"
import DistrictOneBrochure9 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_09.jpg"
import DistrictOneBrochure10 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_10.jpg"
import DistrictOneBrochure11 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_11.jpg"
import DistrictOneBrochure12 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_12.jpg"
import DistrictOneBrochure13 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_13.jpg"
import DistrictOneBrochure14 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_14.jpg"
import DistrictOneBrochure15 from "../../../static/images/files/D1 West/b. Brochure/D1West_Main Brochure_Page_15.jpg"

import DistrictOneMaterialBoardBrochure1 from "../../../static/images/files/D1 West/d. Material Board Brochure/D1West_Material boards brochure-3-_Page_01.jpg"
import DistrictOneMaterialBoardBrochure2 from "../../../static/images/files/D1 West/d. Material Board Brochure/D1West_Material boards brochure-3-_Page_02.jpg"
import DistrictOneMaterialBoardBrochure3 from "../../../static/images/files/D1 West/d. Material Board Brochure/D1West_Material boards brochure-3-_Page_03.jpg"
import DistrictOneMaterialBoardBrochure4 from "../../../static/images/files/D1 West/d. Material Board Brochure/D1West_Material boards brochure-3-_Page_04.jpg"
import DistrictOneMaterialBoardBrochure5 from "../../../static/images/files/D1 West/d. Material Board Brochure/D1West_Material boards brochure-3-_Page_05.jpg"
import DistrictOneMaterialBoardBrochure6 from "../../../static/images/files/D1 West/d. Material Board Brochure/D1West_Material boards brochure-3-_Page_06.jpg"
import DistrictOneMaterialBoardBrochure7 from "../../../static/images/files/D1 West/d. Material Board Brochure/D1West_Material boards brochure-3-_Page_07.jpg"
import DistrictOneMaterialBoardBrochure8 from "../../../static/images/files/D1 West/d. Material Board Brochure/D1West_Material boards brochure-3-_Page_08.jpg"
import DistrictOneMaterialBoardBrochure9 from "../../../static/images/files/D1 West/d. Material Board Brochure/D1West_Material boards brochure-3-_Page_09.jpg"
import DistrictOneMaterialBoardBrochure10 from "../../../static/images/files/D1 West/d. Material Board Brochure/D1West_Material boards brochure-3-_Page_10.jpg"
import DistrictOneMaterialBoardBrochure11 from "../../../static/images/files/D1 West/d. Material Board Brochure/D1West_Material boards brochure-3-_Page_11.jpg"
import DistrictOneMaterialBoardBrochure12 from "../../../static/images/files/D1 West/d. Material Board Brochure/D1West_Material boards brochure-3-_Page_12.jpg"


import MarkComoResidences from '../../../static/images/markers/como-residences/como-residences.svg'
import VideoComoResidences from '../../../static/images/markers/como-residences/Como_Residences_Animation.mp4'
import Preview1ComoResidences from '../../../static/images/markers/como-residences/image1.png'
import Preview2ComoResidences from '../../../static/images/markers/como-residences/image2.png'
import Preview3ComoResidences from '../../../static/images/markers/como-residences/image3.png'
import Preview4ComoResidences from '../../../static/images/markers/como-residences/image4.png'
import Preview5ComoResidences from '../../../static/images/markers/como-residences/image5.png'
import Preview6ComoResidences from '../../../static/images/markers/como-residences/image6.png'
import Preview7ComoResidences from '../../../static/images/markers/como-residences/image7.png'
import Preview8ComoResidences from '../../../static/images/markers/como-residences/image8.png'

import ComoResidencesPaymentPlan from "../../../static/images/files/Como Residences/a. Payment Plan/Como Payment Plan.jpg"

import ComoResidencesFloorPlans1 from "../../../static/images/files/Como Residences/b. Floorplans/Como Floor Plan - Individuals_Page_01.jpg"
import ComoResidencesFloorPlans2 from "../../../static/images/files/Como Residences/b. Floorplans/Como Floor Plan - Individuals_Page_02.jpg"
import ComoResidencesFloorPlans3 from "../../../static/images/files/Como Residences/b. Floorplans/Como Floor Plan - Individuals_Page_03.jpg"
import ComoResidencesFloorPlans4 from "../../../static/images/files/Como Residences/b. Floorplans/Como Floor Plan - Individuals_Page_04.jpg"
import ComoResidencesFloorPlans5 from "../../../static/images/files/Como Residences/b. Floorplans/Como Floor Plan - Individuals_Page_05.jpg"
import ComoResidencesFloorPlans6 from "../../../static/images/files/Como Residences/b. Floorplans/Como Floor Plan - Individuals_Page_06.jpg"
import ComoResidencesFloorPlans7 from "../../../static/images/files/Como Residences/b. Floorplans/Como Floor Plan - Individuals_Page_07.jpg"
import ComoResidencesFloorPlans8 from "../../../static/images/files/Como Residences/b. Floorplans/Como Floor Plan - Individuals_Page_08.jpg"
import ComoResidencesFloorPlans9 from "../../../static/images/files/Como Residences/b. Floorplans/Como Floor Plan - Individuals_Page_09.jpg"
import ComoResidencesFloorPlans10 from "../../../static/images/files/Como Residences/b. Floorplans/Como Floor Plan - Individuals_Page_10.jpg"
import ComoResidencesFloorPlans11 from "../../../static/images/files/Como Residences/b. Floorplans/Como Floor Plan - Individuals_Page_11.jpg"
import ComoResidencesFloorPlans12 from "../../../static/images/files/Como Residences/b. Floorplans/Como Floor Plan - Individuals_Page_12.jpg"
import ComoResidencesFloorPlans13 from "../../../static/images/files/Como Residences/b. Floorplans/Como Floor Plan - Individuals_Page_13.jpg"
import ComoResidencesFloorPlans14 from "../../../static/images/files/Como Residences/b. Floorplans/Como Floor Plan - Individuals_Page_14.jpg"
import ComoResidencesFloorPlans15 from "../../../static/images/files/Como Residences/b. Floorplans/Como Floor Plan - Individuals_Page_15.jpg"

import ComoResidencesMaterialBoards1 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-01.jpg"
import ComoResidencesMaterialBoards2 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-02.jpg"
import ComoResidencesMaterialBoards3 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-03.jpg"
import ComoResidencesMaterialBoards4 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-04.jpg"
import ComoResidencesMaterialBoards5 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-05.jpg"
import ComoResidencesMaterialBoards6 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-06.jpg"
import ComoResidencesMaterialBoards7 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-07.jpg"
import ComoResidencesMaterialBoards8 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-08.jpg"
import ComoResidencesMaterialBoards9 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-09.jpg"
import ComoResidencesMaterialBoards10 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-10.jpg"
import ComoResidencesMaterialBoards11 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-11.jpg"
import ComoResidencesMaterialBoards12 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-12.jpg"
import ComoResidencesMaterialBoards13 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-13.jpg"
import ComoResidencesMaterialBoards14 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-14.jpg"
import ComoResidencesMaterialBoards15 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-15.jpg"
import ComoResidencesMaterialBoards16 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-16.jpg"
import ComoResidencesMaterialBoards17 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-17.jpg"
import ComoResidencesMaterialBoards18 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-18.jpg"
import ComoResidencesMaterialBoards19 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-19.jpg"
import ComoResidencesMaterialBoards20 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-20.jpg"
import ComoResidencesMaterialBoards21 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-21.jpg"
import ComoResidencesMaterialBoards22 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-22.jpg"
import ComoResidencesMaterialBoards23 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-23.jpg"
import ComoResidencesMaterialBoards24 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-24.jpg"
import ComoResidencesMaterialBoards25 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-25.jpg"
import ComoResidencesMaterialBoards26 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-26.jpg"
import ComoResidencesMaterialBoards27 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-27.jpg"
import ComoResidencesMaterialBoards28 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-28.jpg"
import ComoResidencesMaterialBoards29 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-29.jpg"
import ComoResidencesMaterialBoards30 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-30.jpg"
import ComoResidencesMaterialBoards31 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-31.jpg"
import ComoResidencesMaterialBoards32 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-32.jpg"
import ComoResidencesMaterialBoards33 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-33.jpg"
import ComoResidencesMaterialBoards34 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-34.jpg"
import ComoResidencesMaterialBoards35 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-35.jpg"
import ComoResidencesMaterialBoards36 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-36.jpg"
import ComoResidencesMaterialBoards37 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-37.jpg"
import ComoResidencesMaterialBoards38 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-38.jpg"
import ComoResidencesMaterialBoards39 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-39.jpg"
import ComoResidencesMaterialBoards40 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-40.jpg"
import ComoResidencesMaterialBoards41 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-41.jpg"
import ComoResidencesMaterialBoards42 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-42.jpg"
import ComoResidencesMaterialBoards43 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-43.jpg"
import ComoResidencesMaterialBoards44 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-44.jpg"
import ComoResidencesMaterialBoards45 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-45.jpg"
import ComoResidencesMaterialBoards46 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-46.jpg"
import ComoResidencesMaterialBoards47 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-47.jpg"
import ComoResidencesMaterialBoards48 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-48.jpg"
import ComoResidencesMaterialBoards49 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-49.jpg"
import ComoResidencesMaterialBoards50 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-50.jpg"
import ComoResidencesMaterialBoards51 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-51.jpg"
import ComoResidencesMaterialBoards52 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-52.jpg"
import ComoResidencesMaterialBoards53 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-53.jpg"
import ComoResidencesMaterialBoards54 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-54.jpg"
import ComoResidencesMaterialBoards55 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-55.jpg"
import ComoResidencesMaterialBoards56 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-56.jpg"
import ComoResidencesMaterialBoards57 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-57.jpg"
import ComoResidencesMaterialBoards58 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-58.jpg"
import ComoResidencesMaterialBoards59 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-59.jpg"
import ComoResidencesMaterialBoards60 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-60.jpg"
import ComoResidencesMaterialBoards61 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-61.jpg"
import ComoResidencesMaterialBoards62 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-62.jpg"
import ComoResidencesMaterialBoards63 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-63.jpg"
import ComoResidencesMaterialBoards64 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-64.jpg"
import ComoResidencesMaterialBoards65 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-65.jpg"
import ComoResidencesMaterialBoards66 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-66.jpg"
import ComoResidencesMaterialBoards67 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-67.jpg"
import ComoResidencesMaterialBoards68 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-68.jpg"
import ComoResidencesMaterialBoards69 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-69.jpg"
import ComoResidencesMaterialBoards70 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-70.jpg"
import ComoResidencesMaterialBoards71 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-71.jpg"
import ComoResidencesMaterialBoards72 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-72.jpg"
import ComoResidencesMaterialBoards73 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-73.jpg"
import ComoResidencesMaterialBoards74 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-74.jpg"
import ComoResidencesMaterialBoards75 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-75.jpg"
import ComoResidencesMaterialBoards76 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-76.jpg"
import ComoResidencesMaterialBoards77 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-77.jpg"
import ComoResidencesMaterialBoards78 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-78.jpg"
import ComoResidencesMaterialBoards79 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-79.jpg"
import ComoResidencesMaterialBoards80 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-80.jpg"
import ComoResidencesMaterialBoards81 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-81.jpg"
import ComoResidencesMaterialBoards82 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-82.jpg"
import ComoResidencesMaterialBoards83 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-83.jpg"
import ComoResidencesMaterialBoards84 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-84.jpg"
import ComoResidencesMaterialBoards85 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-85.jpg"
import ComoResidencesMaterialBoards86 from "../../../static/images/files/ComoPT/ComoResidences_Brochure_rev24_pt_PT-86.jpg"


import MarkThePalmTowerResidences from '../../../static/images/markers/the-palm-tower-residences/the-palm-tower-residences.svg'
import MarkStRegisDubaiThePalm from '../../../static/images/markers/st-regis-dubai-the-palm/st-regis-dubai-the-palm.svg'


import MarkPalmBeachTowers from '../../../static/images/markers/palm-beach-towers/palm-beach-towers.svg'
import PalmBeachTowersVideo from '../../../static/images/markers/palm-beach-towers/video.mp4'

import PalmBeachTowersPreview1 from '../../../static/images/markers/palm-beach-towers/Previews/23_06_22_GATEWAY_A_POOL3-TREES_OPT.jpg'
import PalmBeachTowersPreview2 from '../../../static/images/markers/palm-beach-towers/Previews/23_06_22_GATEWAY_A_POOL3_OPT.jpg'
import PalmBeachTowersPreview3 from '../../../static/images/markers/palm-beach-towers/Previews/23_06_22_GATEWAY_A_POOL_NOWOMAN-copy_OPT.jpg'
import PalmBeachTowersPreview4 from '../../../static/images/markers/palm-beach-towers/Previews/23_06_22_GATEWAY_A_POOL_NOWOMAN_OPT.jpg'
import PalmBeachTowersPreview5 from '../../../static/images/markers/palm-beach-towers/Previews/23_06_22_GATEWAY_BEACH_OPT.jpg'
import PalmBeachTowersPreview6 from '../../../static/images/markers/palm-beach-towers/Previews/23_06_22_GATEWAY_POOL2-WOMAN_OPT.jpg'
import PalmBeachTowersPreview7 from '../../../static/images/markers/palm-beach-towers/Previews/23_06_22_GATEWAY_POOL2_OPT.jpg'
import PalmBeachTowersPreview8 from '../../../static/images/markers/palm-beach-towers/Previews/23_06_22_GATEWAY_POOL_OPT.jpg'
import PalmBeachTowersPreview9 from '../../../static/images/markers/palm-beach-towers/Previews/A964rz92_1ejq66n_kw8_OPT.jpg'
import PalmBeachTowersPreview10 from '../../../static/images/markers/palm-beach-towers/Previews/A9aztd4i_1ejq66j_kw8.jpg'
import PalmBeachTowersPreview11 from '../../../static/images/markers/palm-beach-towers/Previews/v01_OPT.jpg'
import PalmBeachTowersPreview12 from '../../../static/images/markers/palm-beach-towers/Previews/v03_OPT.jpg'
import PalmBeachTowersPreview13 from '../../../static/images/markers/palm-beach-towers/Previews/v2_OPT.jpg'
import PalmBeachTowersPreview14 from '../../../static/images/markers/palm-beach-towers/Previews/v5-day_OPT.jpg'
import PalmBeachTowersPreview15 from '../../../static/images/markers/palm-beach-towers/Previews/v5-night_OPT.jpg'
import PalmBeachTowersPreview16 from '../../../static/images/markers/palm-beach-towers/Previews/v8_OPT.jpg'
import PalmBeachTowersPreview17 from '../../../static/images/markers/palm-beach-towers/Previews/v9_OPT.jpg'

import PalmBeachTowersFloorPlans1_1 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 1/PBT Tower 1 Floor Plans_Page_01.jpg"
import PalmBeachTowersFloorPlans1_2 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 1/PBT Tower 1 Floor Plans_Page_02.jpg"
import PalmBeachTowersFloorPlans1_3 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 1/PBT Tower 1 Floor Plans_Page_03.jpg"
import PalmBeachTowersFloorPlans1_4 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 1/PBT Tower 1 Floor Plans_Page_04.jpg"
import PalmBeachTowersFloorPlans1_5 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 1/PBT Tower 1 Floor Plans_Page_05.jpg"
import PalmBeachTowersFloorPlans1_6 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 1/PBT Tower 1 Floor Plans_Page_06.jpg"
import PalmBeachTowersFloorPlans1_7 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 1/PBT Tower 1 Floor Plans_Page_07.jpg"
import PalmBeachTowersFloorPlans1_8 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 1/PBT Tower 1 Floor Plans_Page_08.jpg"
import PalmBeachTowersFloorPlans1_9 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 1/PBT Tower 1 Floor Plans_Page_09.jpg"
import PalmBeachTowersFloorPlans1_10 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 1/PBT Tower 1 Floor Plans_Page_10.jpg"
import PalmBeachTowersFloorPlans1_11 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 1/PBT Tower 1 Floor Plans_Page_11.jpg"
import PalmBeachTowersFloorPlans1_12 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 1/PBT Tower 1 Floor Plans_Page_12.jpg"
import PalmBeachTowersFloorPlans1_13 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 1/PBT Tower 1 Floor Plans_Page_13.jpg"
import PalmBeachTowersFloorPlans1_14 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 1/PBT Tower 1 Floor Plans_Page_14.jpg"
import PalmBeachTowersFloorPlans1_15 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 1/PBT Tower 1 Floor Plans_Page_15.jpg"

import PalmBeachTowersFloorPlans2_1 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 2/PBT Tower 2 Floor Plans_Page_01.jpg"
import PalmBeachTowersFloorPlans2_2 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 2/PBT Tower 2 Floor Plans_Page_02.jpg"
import PalmBeachTowersFloorPlans2_3 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 2/PBT Tower 2 Floor Plans_Page_03.jpg"
import PalmBeachTowersFloorPlans2_4 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 2/PBT Tower 2 Floor Plans_Page_04.jpg"
import PalmBeachTowersFloorPlans2_5 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 2/PBT Tower 2 Floor Plans_Page_05.jpg"
import PalmBeachTowersFloorPlans2_6 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 2/PBT Tower 2 Floor Plans_Page_06.jpg"
import PalmBeachTowersFloorPlans2_7 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 2/PBT Tower 2 Floor Plans_Page_07.jpg"
import PalmBeachTowersFloorPlans2_8 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 2/PBT Tower 2 Floor Plans_Page_08.jpg"
import PalmBeachTowersFloorPlans2_9 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 2/PBT Tower 2 Floor Plans_Page_09.jpg"
import PalmBeachTowersFloorPlans2_10 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 2/PBT Tower 2 Floor Plans_Page_10.jpg"
import PalmBeachTowersFloorPlans2_11 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 2/PBT Tower 2 Floor Plans_Page_11.jpg"
import PalmBeachTowersFloorPlans2_12 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 2/PBT Tower 2 Floor Plans_Page_12.jpg"
import PalmBeachTowersFloorPlans2_13 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 2/PBT Tower 2 Floor Plans_Page_13.jpg"
import PalmBeachTowersFloorPlans2_14 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 2/PBT Tower 2 Floor Plans_Page_14.jpg"
import PalmBeachTowersFloorPlans2_15 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 2/PBT Tower 2 Floor Plans_Page_15.jpg"

import PalmBeachTowersFloorPlans3_1 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 3/PBT Tower 3 Floor Plans_Page_01.jpg"
import PalmBeachTowersFloorPlans3_2 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 3/PBT Tower 3 Floor Plans_Page_02.jpg"
import PalmBeachTowersFloorPlans3_3 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 3/PBT Tower 3 Floor Plans_Page_03.jpg"
import PalmBeachTowersFloorPlans3_4 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 3/PBT Tower 3 Floor Plans_Page_04.jpg"
import PalmBeachTowersFloorPlans3_5 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 3/PBT Tower 3 Floor Plans_Page_05.jpg"
import PalmBeachTowersFloorPlans3_6 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 3/PBT Tower 3 Floor Plans_Page_06.jpg"
import PalmBeachTowersFloorPlans3_7 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 3/PBT Tower 3 Floor Plans_Page_07.jpg"
import PalmBeachTowersFloorPlans3_8 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 3/PBT Tower 3 Floor Plans_Page_08.jpg"
import PalmBeachTowersFloorPlans3_9 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 3/PBT Tower 3 Floor Plans_Page_09.jpg"
import PalmBeachTowersFloorPlans3_10 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 3/PBT Tower 3 Floor Plans_Page_10.jpg"
import PalmBeachTowersFloorPlans3_11 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 3/PBT Tower 3 Floor Plans_Page_11.jpg"
import PalmBeachTowersFloorPlans3_12 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 3/PBT Tower 3 Floor Plans_Page_12.jpg"
import PalmBeachTowersFloorPlans3_13 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 3/PBT Tower 3 Floor Plans_Page_13.jpg"
import PalmBeachTowersFloorPlans3_14 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 3/PBT Tower 3 Floor Plans_Page_14.jpg"
import PalmBeachTowersFloorPlans3_15 from "../../../static/images/files/Palm Beach Towers/a. FloorPlans/Tower 3/PBT Tower 3 Floor Plans_Page_15.jpg"

import PalmBeachTowersBrochure1 from "../../../static/images/files/Palm Beach TowerPT/Palm Beact Tower 3-01.jpg"
import PalmBeachTowersBrochure2 from "../../../static/images/files/Palm Beach TowerPT/Palm Beact Tower 3-02.jpg"
import PalmBeachTowersBrochure3 from "../../../static/images/files/Palm Beach TowerPT/Palm Beact Tower 3-03.jpg"
import PalmBeachTowersBrochure4 from "../../../static/images/files/Palm Beach TowerPT/Palm Beact Tower 3-04.jpg"
import PalmBeachTowersBrochure5 from "../../../static/images/files/Palm Beach TowerPT/Palm Beact Tower 3-05.jpg"
import PalmBeachTowersBrochure6 from "../../../static/images/files/Palm Beach TowerPT/Palm Beact Tower 3-06.jpg"
import PalmBeachTowersBrochure7 from "../../../static/images/files/Palm Beach TowerPT/Palm Beact Tower 3-07.jpg"
import PalmBeachTowersBrochure8 from "../../../static/images/files/Palm Beach TowerPT/Palm Beact Tower 3-08.jpg"
import PalmBeachTowersBrochure9 from "../../../static/images/files/Palm Beach TowerPT/Palm Beact Tower 3-09.jpg"
import PalmBeachTowersBrochure10 from "../../../static/images/files/Palm Beach TowerPT/Palm Beact Tower 3-10.jpg"
import PalmBeachTowersBrochure11 from "../../../static/images/files/Palm Beach TowerPT/Palm Beact Tower 3-11.jpg"
import PalmBeachTowersBrochure12 from "../../../static/images/files/Palm Beach TowerPT/Palm Beact Tower 3-12.jpg"
import PalmBeachTowersBrochure13 from "../../../static/images/files/Palm Beach TowerPT/Palm Beact Tower 3-13.jpg"
import PalmBeachTowersBrochure14 from "../../../static/images/files/Palm Beach TowerPT/Palm Beact Tower 3-14.jpg"
import PalmBeachTowersBrochure15 from "../../../static/images/files/Palm Beach TowerPT/Palm Beact Tower 3-15.jpg"
import PalmBeachTowersBrochure16 from "../../../static/images/files/Palm Beach TowerPT/Palm Beact Tower 3-16.jpg"
import PalmBeachTowersBrochure17 from "../../../static/images/files/Palm Beach TowerPT/Palm Beact Tower 3-17.jpg"
import PalmBeachTowersBrochure18 from "../../../static/images/files/Palm Beach TowerPT/Palm Beact Tower 3-18.jpg"

import MarkTheWorld from '../../../static/images/markers/the-world/the-world.svg'
import MarkNadAlShebaVillas from '../../../static/images/markers/nad-al-sheba-villas/nad-al-sheba-villas.svg'
import MarkWarsanVillage from '../../../static/images/markers/warsan-village/warsan-village.svg'
import MarkInternationalCity from '../../../static/images/markers/international-city/international-city.svg'


import MarkCanalFrontResidences from '../../../static/images/markers/canal-front-residences/canal-front-residences.svg'
import CanalFrontResidencesVideo from '../../../static/images/markers/canal-front-residences/Canal Front Residence 6 Video.mp4'
import CanalFrontResidencesPreview1 from '../../../static/images/markers/canal-front-residences/Images/preview1.jpg'
import CanalFrontResidencesPreview2 from '../../../static/images/markers/canal-front-residences/Images/preview2.jpg'
import CanalFrontResidencesPreview3 from '../../../static/images/markers/canal-front-residences/Images/preview3.jpg'
import CanalFrontResidencesPreview4 from '../../../static/images/markers/canal-front-residences/Images/preview4.jpg'
import CanalFrontResidencesPreview5 from '../../../static/images/markers/canal-front-residences/Images/preview5.jpg'

import CanalFrontResidencesBrochure1 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_01.jpg"
import CanalFrontResidencesBrochure2 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_02.jpg"
import CanalFrontResidencesBrochure3 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_03.jpg"
import CanalFrontResidencesBrochure4 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_04.jpg"
import CanalFrontResidencesBrochure5 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_05.jpg"
import CanalFrontResidencesBrochure6 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_06.jpg"
import CanalFrontResidencesBrochure7 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_07.jpg"
import CanalFrontResidencesBrochure8 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_08.jpg"
import CanalFrontResidencesBrochure9 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_09.jpg"
import CanalFrontResidencesBrochure10 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_10.jpg"
import CanalFrontResidencesBrochure11 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_11.jpg"
import CanalFrontResidencesBrochure12 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_12.jpg"
import CanalFrontResidencesBrochure13 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_13.jpg"
import CanalFrontResidencesBrochure14 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_14.jpg"
import CanalFrontResidencesBrochure15 from "../../../static/images/files/Canal Front Residences/a. Brochure/CanalFront_Brochure_300921_Digital R1_Page_15.jpg"

import CanalFrontResidencesFloorPlans1 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_01.jpg"
import CanalFrontResidencesFloorPlans2 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_02.jpg"
import CanalFrontResidencesFloorPlans3 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_03.jpg"
import CanalFrontResidencesFloorPlans4 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_04.jpg"
import CanalFrontResidencesFloorPlans5 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_05.jpg"
import CanalFrontResidencesFloorPlans6 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_06.jpg"
import CanalFrontResidencesFloorPlans7 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_07.jpg"
import CanalFrontResidencesFloorPlans8 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_08.jpg"
import CanalFrontResidencesFloorPlans9 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_09.jpg"
import CanalFrontResidencesFloorPlans10 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_10.jpg"
import CanalFrontResidencesFloorPlans11 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_11.jpg"
import CanalFrontResidencesFloorPlans12 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_12.jpg"
import CanalFrontResidencesFloorPlans13 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_13.jpg"
import CanalFrontResidencesFloorPlans14 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_14.jpg"
import CanalFrontResidencesFloorPlans15 from "../../../static/images/files/Canal Front Residences/b. Floorplans/Canal Front Residence 6 Floor Plans_Page_15.jpg"

import CanalFrontResidencesFactSheet1 from "../../../static/images/files/Canal Front Residences/c. FactSheet/Canal Front Residence 6 Factsheet_Page_1.jpg"
import CanalFrontResidencesFactSheet2 from "../../../static/images/files/Canal Front Residences/c. FactSheet/Canal Front Residence 6 Factsheet_Page_2.jpg"
import CanalFrontResidencesFactSheet3 from "../../../static/images/files/Canal Front Residences/c. FactSheet/Canal Front Residence 6 Factsheet_Page_3.jpg"
import CanalFrontResidencesFactSheet4 from "../../../static/images/files/Canal Front Residences/c. FactSheet/Canal Front Residence 6 Factsheet_Page_4.jpg"
import CanalFrontResidencesFactSheet5 from "../../../static/images/files/Canal Front Residences/c. FactSheet/Canal Front Residence 6 Factsheet_Page_5.jpg"

import CanalFrontResidencesPaymentPlan from "../../../static/images/files/Canal Front Residences/d. Payment Plan/Canal Front Residence 6 Payment Plan.jpg"


import MarkDistrictOne from '../../../static/images/markers/district-one/district-one.svg'


import MarkDistrictOneWest from '../../../static/images/markers/district-one-west/district-one-west.svg'
import DistrictOneWestPreview1 from '../../../static/images/markers/district-one-west-1/Renders/preview1.jpg'
import DistrictOneWestPreview2 from '../../../static/images/markers/district-one-west-1/Renders/7.jpg'
import DistrictOneWestPreview3 from '../../../static/images/markers/district-one-west-1/Renders/preview2.jpg'
import DistrictOneWestPreview4 from '../../../static/images/markers/district-one-west-1/Renders/preview3.jpg'
import DistrictOneWestPreview5 from '../../../static/images/markers/district-one-west-1/Renders/preview4.jpg'
import DistrictOneWestPreview6 from '../../../static/images/markers/district-one-west-1/Renders/preview5.jpg'


import MarkMeydanAvenue from '../../../static/images/markers/meydan-avenue/meydan-avenue.svg'
import MeydanAvenuePreview1 from '../../../static/images/markers/meydan-avenue/Renders/preview1.jpg'
import MeydanAvenuePreview2 from '../../../static/images/markers/meydan-avenue/Renders/preview2.jpg'
import MeydanAvenuePreview3 from '../../../static/images/markers/meydan-avenue/Renders/preview3.jpg'
import MeydanAvenuePreview4 from '../../../static/images/markers/meydan-avenue/Renders/preview4.jpg'
import MeydanAvenuePreview5 from '../../../static/images/markers/meydan-avenue/Renders/preview5.jpg'
import MeydanAvenuePreview6 from '../../../static/images/markers/meydan-avenue/Renders/preview6.jpg'

import MeydanAvenuePlotABrochure1 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_01.jpg"
import MeydanAvenuePlotABrochure2 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_02.jpg"
import MeydanAvenuePlotABrochure3 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_03.jpg"
import MeydanAvenuePlotABrochure4 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_04.jpg"
import MeydanAvenuePlotABrochure5 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_05.jpg"
import MeydanAvenuePlotABrochure6 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_06.jpg"
import MeydanAvenuePlotABrochure7 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_07.jpg"
import MeydanAvenuePlotABrochure8 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_08.jpg"
import MeydanAvenuePlotABrochure9 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_09.jpg"
import MeydanAvenuePlotABrochure10 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_10.jpg"
import MeydanAvenuePlotABrochure11 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_11.jpg"
import MeydanAvenuePlotABrochure12 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_12.jpg"
import MeydanAvenuePlotABrochure13 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_13.jpg"
import MeydanAvenuePlotABrochure14 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_14.jpg"
import MeydanAvenuePlotABrochure15 from "../../../static/images/files/Meydan Avenue/a. Plot A/Brochure/iii. Brochure/Meydan Avenue A Brochure_Page_15.jpg"

import MeydanAvenuePlotAPlotDesign1 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_01.jpg"
import MeydanAvenuePlotAPlotDesign2 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_02.jpg"
import MeydanAvenuePlotAPlotDesign3 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_03.jpg"
import MeydanAvenuePlotAPlotDesign4 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_04.jpg"
import MeydanAvenuePlotAPlotDesign5 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_05.jpg"
import MeydanAvenuePlotAPlotDesign6 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_06.jpg"
import MeydanAvenuePlotAPlotDesign7 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_07.jpg"
import MeydanAvenuePlotAPlotDesign8 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_08.jpg"
import MeydanAvenuePlotAPlotDesign9 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_09.jpg"
import MeydanAvenuePlotAPlotDesign10 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_10.jpg"
import MeydanAvenuePlotAPlotDesign11 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_11.jpg"
import MeydanAvenuePlotAPlotDesign12 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_12.jpg"
import MeydanAvenuePlotAPlotDesign13 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_13.jpg"
import MeydanAvenuePlotAPlotDesign14 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_14.jpg"
import MeydanAvenuePlotAPlotDesign15 from "../../../static/images/files/Meydan Avenue/a. Plot A/i. Plot Design Guidelines/meydan avenue plot a - plot design guidelines_Page_15.jpg"

import MeydanAvenuePlotADevControl1 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_01.jpg"
import MeydanAvenuePlotADevControl2 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_02.jpg"
import MeydanAvenuePlotADevControl3 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_03.jpg"
import MeydanAvenuePlotADevControl4 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_04.jpg"
import MeydanAvenuePlotADevControl5 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_05.jpg"
import MeydanAvenuePlotADevControl6 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_06.jpg"
import MeydanAvenuePlotADevControl7 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_07.jpg"
import MeydanAvenuePlotADevControl8 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_08.jpg"
import MeydanAvenuePlotADevControl9 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_09.jpg"
import MeydanAvenuePlotADevControl10 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_10.jpg"
import MeydanAvenuePlotADevControl11 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_11.jpg"
import MeydanAvenuePlotADevControl12 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_12.jpg"
import MeydanAvenuePlotADevControl13 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_13.jpg"
import MeydanAvenuePlotADevControl14 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_14.jpg"
import MeydanAvenuePlotADevControl15 from "../../../static/images/files/Meydan Avenue/a. Plot A/ii. Development Control Guidelines/meydan avenue plot a - development control regulations_Page_15.jpg"

import MeydanAvenuePlotBBrochure1 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_01.jpg"
import MeydanAvenuePlotBBrochure2 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_02.jpg"
import MeydanAvenuePlotBBrochure3 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_03.jpg"
import MeydanAvenuePlotBBrochure4 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_04.jpg"
import MeydanAvenuePlotBBrochure5 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_05.jpg"
import MeydanAvenuePlotBBrochure6 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_06.jpg"
import MeydanAvenuePlotBBrochure7 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_07.jpg"
import MeydanAvenuePlotBBrochure8 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_08.jpg"
import MeydanAvenuePlotBBrochure9 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_09.jpg"
import MeydanAvenuePlotBBrochure10 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_10.jpg"
import MeydanAvenuePlotBBrochure11 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_11.jpg"
import MeydanAvenuePlotBBrochure12 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_12.jpg"
import MeydanAvenuePlotBBrochure13 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_13.jpg"
import MeydanAvenuePlotBBrochure14 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_14.jpg"
import MeydanAvenuePlotBBrochure15 from "../../../static/images/files/Meydan Avenue/b. Plot B/Brochure/iii. Brochure/Meydan Avenue B Brochure_Page_15.jpg"

import MeydanAvenuePlotBPlotDesign1 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_01.jpg"
import MeydanAvenuePlotBPlotDesign2 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_02.jpg"
import MeydanAvenuePlotBPlotDesign3 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_03.jpg"
import MeydanAvenuePlotBPlotDesign4 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_04.jpg"
import MeydanAvenuePlotBPlotDesign5 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_05.jpg"
import MeydanAvenuePlotBPlotDesign6 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_06.jpg"
import MeydanAvenuePlotBPlotDesign7 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_07.jpg"
import MeydanAvenuePlotBPlotDesign8 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_08.jpg"
import MeydanAvenuePlotBPlotDesign9 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_09.jpg"
import MeydanAvenuePlotBPlotDesign10 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_10.jpg"
import MeydanAvenuePlotBPlotDesign11 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_11.jpg"
import MeydanAvenuePlotBPlotDesign12 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_12.jpg"
import MeydanAvenuePlotBPlotDesign13 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_13.jpg"
import MeydanAvenuePlotBPlotDesign14 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_14.jpg"
import MeydanAvenuePlotBPlotDesign15 from "../../../static/images/files/Meydan Avenue/b. Plot B/i. Plot Design Guidelines/meydan avenue plot b - plot design guidelines_Page_15.jpg"

import MeydanAvenuePlotBDevControl1 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_01.jpg"
import MeydanAvenuePlotBDevControl2 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_02.jpg"
import MeydanAvenuePlotBDevControl3 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_03.jpg"
import MeydanAvenuePlotBDevControl4 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_04.jpg"
import MeydanAvenuePlotBDevControl5 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_05.jpg"
import MeydanAvenuePlotBDevControl6 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_06.jpg"
import MeydanAvenuePlotBDevControl7 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_07.jpg"
import MeydanAvenuePlotBDevControl8 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_08.jpg"
import MeydanAvenuePlotBDevControl9 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_09.jpg"
import MeydanAvenuePlotBDevControl10 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_10.jpg"
import MeydanAvenuePlotBDevControl11 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_11.jpg"
import MeydanAvenuePlotBDevControl12 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_12.jpg"
import MeydanAvenuePlotBDevControl13 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_13.jpg"
import MeydanAvenuePlotBDevControl14 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_14.jpg"
import MeydanAvenuePlotBDevControl15 from "../../../static/images/files/Meydan Avenue/b. Plot B/ii. Development Control Guidelines/meydan avenue plot b - development control regulations_Page_15.jpg"

import MeydanAvenuePlotCBrochure1 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_01.jpg"
import MeydanAvenuePlotCBrochure2 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_02.jpg"
import MeydanAvenuePlotCBrochure3 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_03.jpg"
import MeydanAvenuePlotCBrochure4 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_04.jpg"
import MeydanAvenuePlotCBrochure5 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_05.jpg"
import MeydanAvenuePlotCBrochure6 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_06.jpg"
import MeydanAvenuePlotCBrochure7 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_07.jpg"
import MeydanAvenuePlotCBrochure8 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_08.jpg"
import MeydanAvenuePlotCBrochure9 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_09.jpg"
import MeydanAvenuePlotCBrochure10 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_10.jpg"
import MeydanAvenuePlotCBrochure11 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_11.jpg"
import MeydanAvenuePlotCBrochure12 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_12.jpg"
import MeydanAvenuePlotCBrochure13 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_13.jpg"
import MeydanAvenuePlotCBrochure14 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_14.jpg"
import MeydanAvenuePlotCBrochure15 from "../../../static/images/files/Meydan Avenue/c. Plot C/Brochure/iii. Brochure/Meydan Avenue C Brochure_Page_15.jpg"

import MeydanAvenuePlotCPlotDesign1 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_01.jpg"
import MeydanAvenuePlotCPlotDesign2 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_02.jpg"
import MeydanAvenuePlotCPlotDesign3 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_03.jpg"
import MeydanAvenuePlotCPlotDesign4 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_04.jpg"
import MeydanAvenuePlotCPlotDesign5 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_05.jpg"
import MeydanAvenuePlotCPlotDesign6 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_06.jpg"
import MeydanAvenuePlotCPlotDesign7 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_07.jpg"
import MeydanAvenuePlotCPlotDesign8 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_08.jpg"
import MeydanAvenuePlotCPlotDesign9 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_09.jpg"
import MeydanAvenuePlotCPlotDesign10 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_10.jpg"
import MeydanAvenuePlotCPlotDesign11 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_11.jpg"
import MeydanAvenuePlotCPlotDesign12 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_12.jpg"
import MeydanAvenuePlotCPlotDesign13 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_13.jpg"
import MeydanAvenuePlotCPlotDesign14 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_14.jpg"
import MeydanAvenuePlotCPlotDesign15 from "../../../static/images/files/Meydan Avenue/c. Plot C/i. Plot Design Guidelines/Meydan Avenue C - Plot Development Guidelines_Page_15.jpg"

import MeydanAvenuePlotCDevControl1 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_01.jpg"
import MeydanAvenuePlotCDevControl2 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_02.jpg"
import MeydanAvenuePlotCDevControl3 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_03.jpg"
import MeydanAvenuePlotCDevControl4 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_04.jpg"
import MeydanAvenuePlotCDevControl5 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_05.jpg"
import MeydanAvenuePlotCDevControl6 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_06.jpg"
import MeydanAvenuePlotCDevControl7 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_07.jpg"
import MeydanAvenuePlotCDevControl8 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_08.jpg"
import MeydanAvenuePlotCDevControl9 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_09.jpg"
import MeydanAvenuePlotCDevControl10 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_10.jpg"
import MeydanAvenuePlotCDevControl11 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_11.jpg"
import MeydanAvenuePlotCDevControl12 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_12.jpg"
import MeydanAvenuePlotCDevControl13 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_13.jpg"
import MeydanAvenuePlotCDevControl14 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_14.jpg"
import MeydanAvenuePlotCDevControl15 from "../../../static/images/files/Meydan Avenue/c. Plot C/ii. Development Control Guidelines/Meydan Avenue C - Development Control Regulations_Page_15.jpg"


import MarkGolfCourse from '../../../static/images/markers/golf-course/golf-course.svg'
import MarkMeydanHotel from '../../../static/images/markers/meydan-hotel/meydan-hotel.svg'
import MarkPoloResidence from '../../../static/images/markers/polo-residence/polo-residence.svg'


import MarkDistrict11 from '../../../static/images/markers/district-11/district-11.svg'
import District11Preview1 from '../../../static/images/markers/district-11/Renders/Exterior Renders/4_bed.jpg'
import District11Preview2 from '../../../static/images/markers/district-11/Renders/Exterior Renders/4bed_night.jpg'
import District11Preview3 from '../../../static/images/markers/district-11/Renders/Exterior Renders/5_bed.jpg'
import District11Preview4 from '../../../static/images/markers/district-11/Renders/Interior Renders/townhouse_bathroom.jpg'
import District11Preview5 from '../../../static/images/markers/district-11/Renders/Interior Renders/townhouse_livingroom-1.jpg'
import District11Preview6 from '../../../static/images/markers/district-11/Renders/Interior Renders/villa_bathroom-2.jpg'

import District11Brochure1 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_01.jpg"
import District11Brochure2 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_02.jpg"
import District11Brochure3 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_03.jpg"
import District11Brochure4 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_04.jpg"
import District11Brochure5 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_05.jpg"
import District11Brochure6 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_06.jpg"
import District11Brochure7 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_07.jpg"
import District11Brochure8 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_08.jpg"
import District11Brochure9 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_09.jpg"
import District11Brochure10 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_10.jpg"
import District11Brochure11 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_11.jpg"
import District11Brochure12 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_12.jpg"
import District11Brochure13 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_13.jpg"
import District11Brochure14 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_14.jpg"
import District11Brochure15 from "../../../static/images/files/District 11/a Brochure/District 11 - Opal Gardens_Page_15.jpg"

import District11Factsheet1 from "../../../static/images/files/District 11/c. Factsheet/Factsheet_Page_1.jpg"
import District11Factsheet2 from "../../../static/images/files/District 11/c. Factsheet/Factsheet_Page_2.jpg"
import District11Factsheet3 from "../../../static/images/files/District 11/c. Factsheet/Factsheet_Page_3.jpg"
import District11Factsheet4 from "../../../static/images/files/District 11/c. Factsheet/Factsheet_Page_4.jpg"

import District11FloorPlans1 from "../../../static/images/files/District 11/c. Floorplans/District 11 - Opal Gardens_Page_08.jpg"
import District11FloorPlans2 from "../../../static/images/files/District 11/c. Floorplans/District 11 - Opal Gardens_Page_09.jpg"
import District11FloorPlans3 from "../../../static/images/files/District 11/c. Floorplans/District 11 - Opal Gardens_Page_14.jpg"
import District11FloorPlans4 from "../../../static/images/files/District 11/c. Floorplans/District 11 - Opal Gardens_Page_15.jpg"
import District11FloorPlans5 from "../../../static/images/files/District 11/c. Floorplans/District 11 - Opal Gardens_Page_16.jpg"
import District11FloorPlans6 from "../../../static/images/files/District 11/c. Floorplans/District 11 - Opal Gardens_Page_20.jpg"
import District11FloorPlans7 from "../../../static/images/files/District 11/c. Floorplans/District 11 - Opal Gardens_Page_21.jpg"
import District11FloorPlans8 from "../../../static/images/files/District 11/c. Floorplans/District 11 - Opal Gardens_Page_24.jpg"
import District11FloorPlans9 from "../../../static/images/files/District 11/c. Floorplans/District 11 - Opal Gardens_Page_25.jpg"
import District11FloorPlans10 from "../../../static/images/files/District 11/c. Floorplans/District 11 - Opal Gardens_Page_26.jpg"
import District11FloorPlans11 from "../../../static/images/files/District 11/c. Floorplans/District 11 - Opal Gardens_Page_27.jpg"


import MarkAirport from '../../../static/images/landmarks/mark-airport.svg'
import MarkDubaiCanal from '../../../static/images/landmarks/mark-dubai-canal.svg'
import MarkJumeirahBeach from '../../../static/images/landmarks/mark-jumeirah-beach.svg'
import MarkNakheelSales from '../../../static/images/landmarks/mark-nakheel-sales.svg'
import MarkPalmMonorail from '../../../static/images/landmarks/mark-palm-monorail.svg'
import MarkNakheelMarinas from '../../../static/images/landmarks/mark-nakheel-marinas.svg'
import MarkTheViewAtThePalm from '../../../static/images/landmarks/mark-the-view-at-the-palm.svg'
import MarkAirportDWC from '../../../static/images/landmarks/mark-airport-dwc.svg'

import MarkBus1 from '../../../static/images/mark-bus-1.svg'
import MarkBus2 from '../../../static/images/mark-bus-2.svg'
import MarkBus3 from '../../../static/images/mark-bus-3.svg'




import { 
    // Infrastructure
    schools, 
    hospitals, 
    retails, 

    // Polygons
    dubai_islands, 
    palm_jumeirah_1, 
    palm_jumeirah_2,
    palm_jebel_ali_1,
    palm_jebel_ali_2,
    palm_jebel_ali_3,
    the_gardens,
    jumeirah_village_circle,
    jumeirah_village_triangle,
    canal_front_residences,
    district_one,
    district_11,

    // Polylines
    bus_road_1,
    bus_road_2,
    bus_road_3,
    bus_road_1_stops,
    bus_road_2_stops,
    bus_road_3_stops,
    jumeirah_heights,
    international_city_1,
    international_city_2,
    the_world_pol_1,
    the_world_pol_2,
    the_world_pol_3,
    the_world_pol_4,
    the_world_pol_5,
    the_world_pol_6,
    the_world_pol_7,
    the_world_pol_8,
    the_world_pol_9,
    the_world_pol_10,
    the_world_pol_11,
    the_world_pol_12,
    the_world_pol_13,
    the_world_pol_14,
    the_world_pol_15,
    the_world_pol_16,
    the_world_pol_17,
    the_world_pol_18,
    the_world_pol_19,
    the_world_pol_20,
    the_world_pol_21,
    the_world_pol_22,
    the_world_pol_23,
    the_world_pol_24,
    the_world_pol_25,
    the_world_pol_26,
    the_world_pol_27,
    the_world_pol_28,
    the_world_pol_29,
    the_world_pol_30,
    the_world_pol_31,
    the_world_pol_32,
    the_world_pol_33,
    the_world_pol_34,
    the_world_pol_35,
    the_world_pol_36,
    the_world_pol_37,
    the_world_pol_38,
    the_world_pol_39,
    the_world_pol_40,
    the_world_pol_41,
    the_world_pol_42,
    the_world_pol_43,
    the_world_pol_44,
    the_world_pol_45,
    the_world_pol_46,
    the_world_pol_47,
    the_world_pol_48,
    the_world_pol_49,
    the_world_pol_50,
    the_world_pol_51,
    the_world_pol_52,
    the_world_pol_53,
    the_world_pol_54,
    the_world_pol_55,
    the_world_pol_56,
    the_world_pol_57,
    the_world_pol_58,
    the_world_pol_59,
    the_world_pol_60,
    the_world_pol_61,
    the_world_pol_62,
    the_world_pol_63,
    the_world_pol_64,
    the_world_pol_65,
    the_world_pol_66,
    the_world_pol_67,
    the_world_pol_68,
    the_world_pol_69,
    the_world_pol_70,
    the_world_pol_71,
    the_world_pol_72,
    the_world_pol_73,
    the_world_pol_74,
    the_world_pol_75,
    the_world_pol_76,
    the_world_pol_77,
    the_world_pol_78,
    the_world_pol_79,
    the_world_pol_80,
    the_world_pol_81,
    the_world_pol_82,
    the_world_pol_83,
    the_world_pol_84,
    the_world_pol_85,
    the_world_pol_86,
    the_world_pol_87,
    the_world_pol_88,
    the_world_pol_89,
    the_world_pol_90,
    the_world_pol_91,
    the_world_pol_92,
    the_world_pol_93,
    the_world_pol_94,
    the_world_pol_95,
    the_world_pol_96,
    the_world_pol_97,
    the_world_pol_98,
    the_world_pol_99,
    the_world_pol_100,
    the_world_pol_101,
    the_world_pol_102,
    the_world_pol_103,
    the_world_pol_104,
    the_world_pol_105,
    the_world_pol_106,
    the_world_pol_107,
    the_world_pol_108,
    the_world_pol_109,
    the_world_pol_110,
    the_world_pol_111,
    the_world_pol_112,
    the_world_pol_113,
    the_world_pol_114,
    the_world_pol_115,
    the_world_pol_116,
    the_world_pol_117,
    the_world_pol_118,
    the_world_pol_119,
    the_world_pol_120,
    the_world_pol_121,
    the_world_pol_122,
    the_world_pol_123,
    the_world_pol_124,
    the_world_pol_125,
    the_world_pol_126,
    the_world_pol_127,
    the_world_pol_128,
    the_world_pol_129,
    the_world_pol_130,
    the_world_pol_131,
    the_world_pol_132,
    the_world_pol_133,
    the_world_pol_134,
    the_world_pol_135,
    the_world_pol_136,
    the_world_pol_137,
    the_world_pol_138,
    the_world_pol_139,
    the_world_pol_140,
    the_world_pol_141,
    the_world_pol_142,
    the_world_pol_143,
    the_world_pol_144,
    the_world_pol_145,
    the_world_pol_146,
    the_world_pol_147,
    the_world_pol_148,
    the_world_pol_149,
    the_world_pol_150,
    the_world_pol_151,
    the_world_pol_152,
    the_world_pol_153,
    the_world_pol_154,
    the_world_pol_155,
    the_world_pol_156,
    the_world_pol_157,
    the_world_pol_158,
    the_world_pol_159,
    the_world_pol_160,
    the_world_pol_161,
    the_world_pol_162,
    the_world_pol_163,
    the_world_pol_164,
    the_world_pol_165,
    the_world_pol_166,
    the_world_pol_167,
    the_world_pol_168,
    the_world_pol_169,
    the_world_pol_170,
    the_world_pol_171,
    the_world_pol_172,
    the_world_pol_173,
    the_world_pol_174,
    the_world_pol_175,
    the_world_pol_176,
    the_world_pol_177,
    the_world_pol_178,
    the_world_pol_179,
    the_world_pol_180,
    the_world_pol_181,
    the_world_pol_182,
    the_world_pol_183,
    the_world_pol_184,
    the_world_pol_185,
    the_world_pol_186,
    the_world_pol_187,
    the_world_pol_188,
    the_world_pol_189,
    the_world_pol_190,
    the_world_pol_191,
    the_world_pol_192,
    the_world_pol_193,
    the_world_pol_194,
    the_world_pol_195,
    the_world_pol_196,
    the_world_pol_197,
    the_world_pol_198,
    the_world_pol_199,
    the_world_pol_200,
    the_world_pol_201,
    the_world_pol_202,
    the_world_pol_203,    
    the_world_pol_204,
    bus_road_1_2
} from "./Coords";


export const infrastrMarkers = [
    {
        id: 'health',
        allCoords: hospitals,
        icon: MarkHospital
    },
    {
        id: 'education',
        allCoords: schools,
        icon: MarkSchool
    },
    {
        id: 'retail_or_shopping',
        allCoords: retails,
        icon: MarkShopping
    },
]


export const markers = [
    {
        id: 'the_gardens',
        checkIsInGroup: true,
        groupName: ['nakheel', 'plots'],
        zoomLevel: null,
        coords: {lat: 25.0423900606047, lng: 55.1279246642445},
        polygonCoords: [the_gardens],
        icon: MarkTheGardens,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'palm_jebel_ali',
        checkIsInGroup: true,
        groupName: ['nakheel', 'plots', 'villas'],
        zoomLevel: null,
        coords: {lat: 24.9875968817914, lng: 55.0025720069105},
        polygonCoords: [palm_jebel_ali_1, palm_jebel_ali_2, palm_jebel_ali_3],
        icon: MarkPalmJebelAli,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: {
            title: 'Palm Jebel Ali',
            previews: [
                PalmJebelAliPreview1,
                PalmJebelAliPreview2,
                PalmJebelAliPreview3,
                PalmJebelAliPreview4,
                PalmJebelAliPreview5,
                PalmJebelAliPreview6,
                PalmJebelAliPreview7,
                PalmJebelAliPreview8,
                PalmJebelAliPreview9,
            ],
            desc: '',
            link: null,
            video: null,
            buttons: [
                {
                    type: "double",
                    data: [
                        {
                            type: 'img',
                            text: "GUIDELINES",
                            data: [{
                                file: [
                                    PalmJebelAliGuidelines1,
                                    PalmJebelAliGuidelines2,
                                    PalmJebelAliGuidelines3,
                                    PalmJebelAliGuidelines4,
                                    PalmJebelAliGuidelines5,
                                    PalmJebelAliGuidelines6,
                                    PalmJebelAliGuidelines7,
                                    PalmJebelAliGuidelines8,
                                    PalmJebelAliGuidelines9,
                                    PalmJebelAliGuidelines10,
                                    PalmJebelAliGuidelines11,
                                    PalmJebelAliGuidelines12,
                                    PalmJebelAliGuidelines13,
                                    PalmJebelAliGuidelines14,
                                    PalmJebelAliGuidelines15
                                ]
                            }]
                        },
                        {
                            type: 'img',
                            text: "Lotes",
                            data: [
                                {
                                    title: "PLC",
                                    file: [
                                        PalmJebelAliPlotsPLC_1,
                                        PalmJebelAliPlotsPLC_2,
                                        PalmJebelAliPlotsPLC_3,
                                        PalmJebelAliPlotsPLC_4,
                                        PalmJebelAliPlotsPLC_5,
                                        PalmJebelAliPlotsPLC_6,
                                        PalmJebelAliPlotsPLC_7,
                                        PalmJebelAliPlotsPLC_8,
                                        PalmJebelAliPlotsPLC_9,
                                        PalmJebelAliPlotsPLC_10,
                                        PalmJebelAliPlotsPLC_11,
                                        PalmJebelAliPlotsPLC_12,
                                        PalmJebelAliPlotsPLC_13,
                                        PalmJebelAliPlotsPLC_14,
                                        PalmJebelAliPlotsPLC_15
                                    ]
                                },
                                {
                                    title: "DCR",
                                    file: [
                                        PalmJebelAliPlotsDCR_1,
                                        PalmJebelAliPlotsDCR_2,
                                        PalmJebelAliPlotsDCR_3,
                                        PalmJebelAliPlotsDCR_4,
                                        PalmJebelAliPlotsDCR_5,
                                        PalmJebelAliPlotsDCR_6,
                                        PalmJebelAliPlotsDCR_7,
                                        PalmJebelAliPlotsDCR_8,
                                        PalmJebelAliPlotsDCR_9,
                                        PalmJebelAliPlotsDCR_10,
                                        PalmJebelAliPlotsDCR_11,
                                        PalmJebelAliPlotsDCR_12,
                                        PalmJebelAliPlotsDCR_13,
                                        PalmJebelAliPlotsDCR_14,
                                        PalmJebelAliPlotsDCR_15
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    type: "double",
                    data: [
                        {
                            type: 'img',
                            text: "Brochura do quadro de material",
                            data: [{
                                file: [
                                    PalmJebelAliMaterialBoardBrochure1,
                                    PalmJebelAliMaterialBoardBrochure2,
                                    PalmJebelAliMaterialBoardBrochure3,
                                    PalmJebelAliMaterialBoardBrochure4,
                                    PalmJebelAliMaterialBoardBrochure5,
                                    PalmJebelAliMaterialBoardBrochure6,
                                    PalmJebelAliMaterialBoardBrochure7,
                                    PalmJebelAliMaterialBoardBrochure8,
                                    PalmJebelAliMaterialBoardBrochure9,
                                    PalmJebelAliMaterialBoardBrochure10,
                                    PalmJebelAliMaterialBoardBrochure11,
                                    PalmJebelAliMaterialBoardBrochure12,
                                    PalmJebelAliMaterialBoardBrochure13,
                                    PalmJebelAliMaterialBoardBrochure14,
                                    PalmJebelAliMaterialBoardBrochure15
                                ]
                            }]
                        },
                        {
                            type: 'img',
                            text: "Brochura",
                            data: [{
                                file: [
                                    PalmJebelAliBrochure1,
                                    PalmJebelAliBrochure2,
                                    PalmJebelAliBrochure3,
                                    PalmJebelAliBrochure4,
                                    PalmJebelAliBrochure5,
                                    PalmJebelAliBrochure6,
                                    PalmJebelAliBrochure7,
                                    PalmJebelAliBrochure8,
                                    PalmJebelAliBrochure9,
                                    PalmJebelAliBrochure10,
                                    PalmJebelAliBrochure11,
                                    PalmJebelAliBrochure12,
                                    PalmJebelAliBrochure13,
                                    PalmJebelAliBrochure14,
                                    PalmJebelAliBrochure15
                                ]
                            }]
                        }
                    ]
                },
                {
                    type: "double",
                    data: [
                        {
                            type: 'img',
                            text: "Planos de piso",
                            data: [
                                {
                                    title: "UKL",
                                    file: [
                                        PalmJebelAliFloorPlansUKL1,
                                        PalmJebelAliFloorPlansUKL2,
                                        PalmJebelAliFloorPlansUKL3,
                                        PalmJebelAliFloorPlansUKL4,
                                        PalmJebelAliFloorPlansUKL5,
                                        PalmJebelAliFloorPlansUKL6
                                    ]
                                },
                                {
                                    title: "MNOP",
                                    file: [
                                        PalmJebelAliFloorPlansMNOP1,
                                        PalmJebelAliFloorPlansMNOP2,
                                        PalmJebelAliFloorPlansMNOP3
                                    ]
                                },
                            ]
                        },
                        {
                            type: 'img',
                            text: "Plano",
                            data: [{
                                file: [
                                    PalmJebelAliMasterplan1,
                                    PalmJebelAliMasterplan2
                                ]
                            }]
                        }
                    ]
                },
            ]
        }
    },
    {
        id: 'palm_jumeirah',
        checkIsInGroup: true,
        groupName: ['nakheel', 'apartments'],
        zoomLevel: null,
        coords: {lat: 25.1223564585631, lng: 55.1319115423103},
        polygonCoords: [palm_jumeirah_1, palm_jumeirah_2],
        icon: MarkPalmJumeirah,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'veneto',
        checkIsInGroup: true,
        groupName: ['nakheel', 'plots'],
        zoomLevel: null,
        coords: {lat: 24.9548784129207, lng: 54.9978584756706},
        polygonCoords: null,
        icon: MarkVeneto,
        activeIcon: MarkVenetoBlack,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'dubai_islands',
        checkIsInGroup: true,
        groupName: ['nakheel', 'plots_villas_apartments'],
        zoomLevel: null,
        coords: {lat: 25.3208847325933, lng: 55.3034074496439},
        polygonCoords: [dubai_islands],
        icon: MarkDubaiIslands,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: {
            title: 'Dubai Islands',
            previews: [
                DubaiIslandsPreview1,
                DubaiIslandsPreview2
            ],
            desc: '',
            link: 'https://www.canalfrontresidences.meydan.ae/',
            video: DubaiIslandsVideo,
            buttons: [
                {
                    type: "double",
                    data: [
                        {
                            type: 'img',
                            text: "Brochura",
                            data: [{
                                file: [
                                    DubaiIslandsBrochure1,
                                    DubaiIslandsBrochure2,
                                    DubaiIslandsBrochure3,
                                    DubaiIslandsBrochure4,
                                    DubaiIslandsBrochure5,
                                    DubaiIslandsBrochure6,
                                    DubaiIslandsBrochure7,
                                    DubaiIslandsBrochure8,
                                    DubaiIslandsBrochure9,
                                    DubaiIslandsBrochure10,
                                    DubaiIslandsBrochure11,
                                    DubaiIslandsBrochure12,
                                    DubaiIslandsBrochure13,
                                    DubaiIslandsBrochure14,
                                    DubaiIslandsBrochure15
                                ]
                            }]
                        },
                        {
                            type: 'img',
                            text: "DUBAI ISLAND A",
                            data: [{
                                file: [
                                    DubaiIslandsMasterplan1,
                                    DubaiIslandsMasterplan2
                                ]
                            }]
                        }
                    ]
                }
            ]
        }
    },
    {
        id: 'jebel_ali_village',
        checkIsInGroup: true,
        groupName: ['nakheel', 'villas'],
        zoomLevel: 11.5,
        coords: {lat: 25.0298263289557, lng: 55.1313941679255},
        polygonCoords: [],
        icon: MarkJebelAliVillage,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: {
            title: 'Jebel Ali Village',
            previews: [
                JebelAliVillagePreview1,
                JebelAliVillagePreview3,
                JebelAliVillagePreview4,
                JebelAliVillagePreview5,
                JebelAliVillagePreview6,
                JebelAliVillagePreview7,
                JebelAliVillagePreview8,
                JebelAliVillagePreview9,
            ],
            desc: '',
            video: JebelAliVillageVideo,
            buttons: [
                {
                    type: "double",
                    data: [
                        {
                            type: 'img',
                            text: "Brochura",
                            data: [{
                                file: [
                                    JebelAliVillageBrochure1,
                                    JebelAliVillageBrochure2,
                                    JebelAliVillageBrochure3,
                                    JebelAliVillageBrochure4,
                                    JebelAliVillageBrochure5,
                                    JebelAliVillageBrochure6,
                                    JebelAliVillageBrochure7,
                                    JebelAliVillageBrochure8,
                                    JebelAliVillageBrochure9,
                                    JebelAliVillageBrochure10,
                                    JebelAliVillageBrochure11,
                                    JebelAliVillageBrochure12,
                                    JebelAliVillageBrochure13,
                                    JebelAliVillageBrochure14,
                                    JebelAliVillageBrochure15
                                ]
                            }]
                        },
                        {
                            type: 'img',
                            text: "Folha de informação",
                            data: [{
                                file: [
                                    JebelAliVillageFactSheet1,
                                    JebelAliVillageFactSheet2
                                ]
                            }]
                        }
                    ]
                },
                {
                    type: "double",
                    data: [
                        {
                            type: 'img',
                            text: "Planos de piso",
                            data: [{
                                file: [
                                    JebelAliVillageFloorPlan1,
                                    JebelAliVillageFloorPlan2,
                                    JebelAliVillageFloorPlan3,
                                    JebelAliVillageFloorPlan4,
                                    JebelAliVillageFloorPlan5,
                                    JebelAliVillageFloorPlan6,
                                    JebelAliVillageFloorPlan7,
                                    JebelAliVillageFloorPlan8,
                                    JebelAliVillageFloorPlan9,
                                    JebelAliVillageFloorPlan10,
                                    JebelAliVillageFloorPlan11,
                                    JebelAliVillageFloorPlan12,
                                    JebelAliVillageFloorPlan13,
                                    JebelAliVillageFloorPlan14,
                                    JebelAliVillageFloorPlan15
                                ]
                            }]
                        },
                        {
                            type: 'img',
                            text: "Plano principal A3",
                            data: [{file: [JebelAliVillageMasterPlan]}]
                        }
                    ]
                },
                {
                    type: "single",
                    data: [
                        {
                            type: 'img',
                            text: "Plano de pagamento",
                            data: [{file: [JebelAliVillagePaymentPlan]}]
                        },
                    ]
                },
            ]
        }
    },
    {
        id: 'jebel_ali_club',
        checkIsInGroup: true,
        groupName: ['nakheel', 'plots'],
        zoomLevel: 13,
        coords: {lat: 25.0359815063039, lng: 55.115931997769},
        polygonCoords: [],
        icon: MarkJebelAliClub,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'jumeirah_park',
        checkIsInGroup: true,
        groupName: ['nakheel', 'plots'],
        zoomLevel: null,
        coords: {lat: 25.0469980610292, lng: 55.1552081007093},
        polygonCoords: [],
        icon: MarkJumeirahPark,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'jumeirah_heights',
        checkIsInGroup: true,
        groupName: ['nakheel', 'plots'],
        zoomLevel: 12,
        coords: {lat: 25.0667078013068, lng: 55.147858750232},
        polygonCoords: [jumeirah_heights],
        icon: MarkJumeirahHeights,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'jumeirah_islands',
        checkIsInGroup: true,
        groupName: ['nakheel', 'villas'],
        zoomLevel: 11.5,
        coords: {lat: 25.0576104830134, lng: 55.1557827842716},
        polygonCoords: [],
        icon: MarkJumeirahIslands,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'al_furjan',
        checkIsInGroup: true,
        groupName: ['nakheel', 'villas', 'new_project'],
        zoomLevel: null,
        coords: {lat: 25.0251434071843, lng: 55.1471077931815},
        polygonCoords: [],
        icon: MarkAlFurjan,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'murooj_al_furjan',
        checkIsInGroup: true,
        groupName: ['nakheel', 'villas'],
        zoomLevel: 12.5,
        coords: {lat: 25.0303480632162, lng: 55.1384807977714},
        polygonCoords: [],
        icon: MarkMuroojAlFurjan,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'tilal_al_furjan',
        checkIsInGroup: true,
        groupName: ['nakheel', 'villas'],
        zoomLevel: 12,
        coords: {lat: 25.0263591519061, lng: 55.1304321969928},
        polygonCoords: [],
        icon: MarkTilalAlFurjan,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: {
            title: 'Tilal Al Furjan',
            previews: [
                TilalAlFurjanPreview1,
                TilalAlFurjanPreview2,
                TilalAlFurjanPreview3,
                TilalAlFurjanPreview4,
                TilalAlFurjanPreview5,
            ],
            desc: '',
            link: 'https://www.tilalalfurjan.ae/',
            video: TilalAlFurjanVideo,
            buttons: [
                {
                    type: "single",
                    data: [
                        {
                            type: 'img',
                            text: "Planos de pisco e Brochura",
                            data: [{
                                file: [
                                    TilalAlFurjanBrochure1,
                                    TilalAlFurjanBrochure2,
                                    TilalAlFurjanBrochure3,
                                    TilalAlFurjanBrochure4,
                                    TilalAlFurjanBrochure5,
                                    TilalAlFurjanBrochure6,
                                    TilalAlFurjanBrochure7,
                                    TilalAlFurjanBrochure8,
                                    TilalAlFurjanBrochure9,
                                    TilalAlFurjanBrochure10,
                                    TilalAlFurjanBrochure11,
                                    TilalAlFurjanBrochure12,
                                    TilalAlFurjanBrochure13,
                                    TilalAlFurjanBrochure14,
                                    TilalAlFurjanBrochure15
                                ]
                            }]
                        }
                    ]
                },
                {
                    type: "single",
                    data: [
                        {
                            type: 'img',
                            text: "Folha de informação",
                            data: [{
                                file: [
                                    TilalAlFurjanFactSheet1,
                                    TilalAlFurjanFactSheet2,
                                    TilalAlFurjanFactSheet3
                                ]
                            }]
                        }
                    ]
                }
            ]
        }
    },
    {
        id: 'lagoon-views',
        checkIsInGroup: true,
        groupName: ['nakheel', 'apartments'],
        zoomLevel: null,
        coords: {lat: 25.15731045557504, lng: 55.28462817116014},
        polygonCoords: [],
        icon: LagoonViewsMark,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: {
            title: 'Lagoon Views',
            previews: [
                LagoonViewsPreview1,
                LagoonViewsPreview2,
                LagoonViewsPreview3,
                LagoonViewsPreview4,
                LagoonViewsPreview5,
                LagoonViewsPreview6,
            ],
            desc: '',
            link: null,
            video: LagoonViewsVideo,
            buttons: [
                {
                    type: "double",
                    data: [
                        {
                            type: 'img',
                            text: "Plano",
                            data: [{
                                file: [
                                    LagoonViewsMasterplan
                                ]
                            }]
                        },
                        {
                            type: 'img',
                            text: "Plano de pagamento",
                            data: [{
                                file: [
                                    LagoonViewsPaymentPlan
                                ]
                            }]
                        }
                    ]
                },
                {
                    type: "double",
                    data: [
                        {
                            type: 'img',
                            text: "Planos de piso",
                            data: [{
                                file: [
                                    LagoonViewsFloorplan1,
                                    LagoonViewsFloorplan2,
                                    LagoonViewsFloorplan3,
                                    LagoonViewsFloorplan4,
                                    LagoonViewsFloorplan5,
                                    LagoonViewsFloorplan6,
                                    LagoonViewsFloorplan7,
                                    LagoonViewsFloorplan8,
                                    LagoonViewsFloorplan9,
                                    LagoonViewsFloorplan10,
                                    LagoonViewsFloorplan11,
                                    LagoonViewsFloorplan12,
                                    LagoonViewsFloorplan13,
                                    LagoonViewsFloorplan14,
                                    LagoonViewsFloorplan15
                                ]
                            }]
                        },
                        {
                            type: 'img',
                            text: "Brochura",
                            data: [{
                                file: [
                                    LagoonViewsBrochure1,
                                    LagoonViewsBrochure2,
                                    LagoonViewsBrochure3,
                                    LagoonViewsBrochure4,
                                    LagoonViewsBrochure5,
                                    LagoonViewsBrochure6,
                                    LagoonViewsBrochure7,
                                    LagoonViewsBrochure8,
                                    LagoonViewsBrochure9,
                                    LagoonViewsBrochure10,
                                    LagoonViewsBrochure11,
                                    LagoonViewsBrochure12,
                                    LagoonViewsBrochure13,
                                    LagoonViewsBrochure14,
                                    LagoonViewsBrochure15
                                ]
                            }]
                        }
                    ]
                },
            ]
        }
    },
    {
        id: 'masakin_al_furjan',
        checkIsInGroup: true,
        groupName: ['nakheel', 'villas'],
        zoomLevel: 12,
        coords: {lat: 25.0303178662483, lng: 55.1492385030902},
        polygonCoords: [],
        icon: MarkMasakinAlFurjan,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'discovery_gardens',
        checkIsInGroup: true,
        groupName: ['nakheel', 'villas'],
        zoomLevel: 12,
        coords: {lat: 25.039989855318, lng: 55.1440769023553},
        polygonCoords: [],
        icon: MarkDiscoveryGardens,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'jumeirah_village_triangle',
        checkIsInGroup: true,
        groupName: ['nakheel', 'villas'],
        zoomLevel: 11.5,
        coords: {lat: 25.0485210715624, lng: 55.1898620162536},
        polygonCoords: [jumeirah_village_triangle],
        icon: MarkJumeirahVillageTriangle,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'jumeirah_village_circle',
        checkIsInGroup: true,
        groupName: ['nakheel', 'villas'],
        zoomLevel: null,
        coords: {lat: 25.0612529333819, lng: 55.2096228990439},
        polygonCoords: [jumeirah_village_circle],
        icon: MarkJumeirahVillageCircle,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'como_residences',
        checkIsInGroup: true,
        groupName: ['nakheel', 'apartments'],
        zoomLevel: null,
        coords: {lat: 25.1110401298578, lng: 55.1458511956965},
        polygonCoords: [],
        icon: MarkComoResidences,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: {
            title: 'Como Residences',
            previews: [
                Preview7ComoResidences,
                Preview4ComoResidences,
                Preview5ComoResidences,
                Preview6ComoResidences,
                Preview2ComoResidences,
                Preview3ComoResidences,
                Preview8ComoResidences,
                Preview1ComoResidences,
            ],
            desc: 'Fazendo parte da famosa marca Como Hotels and Resorts, o Como Residences possui comodidades e serviços inigualáveis, concebidos para proporcionar aos residentes uma experiência de vida de classe mundial. As deslumbrantes torres de arranha-céus oferecem vistas deslumbrantes sobre o horizonte do Dubai.',
            video: VideoComoResidences,
            link: 'https://www.comobynakheel.com/',
            buttons: [
                {
                    type: "double",
                    data: [
                        {
                            type: 'img',
                            text: "Plano de pagamento",
                            data: [{file: [ComoResidencesPaymentPlan]}]
                        },
                        {
                            type: 'img',
                            text: "Planos de piso",
                            data: [{
                                file: [
                                    ComoResidencesFloorPlans1,
                                    ComoResidencesFloorPlans2,
                                    ComoResidencesFloorPlans3,
                                    ComoResidencesFloorPlans4,
                                    ComoResidencesFloorPlans5,
                                    ComoResidencesFloorPlans6,
                                    ComoResidencesFloorPlans7,
                                    ComoResidencesFloorPlans8,
                                    ComoResidencesFloorPlans9,
                                    ComoResidencesFloorPlans10,
                                    ComoResidencesFloorPlans11,
                                    ComoResidencesFloorPlans12,
                                    ComoResidencesFloorPlans13,
                                    ComoResidencesFloorPlans14,
                                    ComoResidencesFloorPlans15
                                ]
                            }]
                        }
                    ]
                },
                {
                    type: "single",
                    data: [
                        {
                            type: 'img',
                            text: "Brochura",
                            data: [{
                                file: [
                                    ComoResidencesMaterialBoards1,
                                    ComoResidencesMaterialBoards2,
                                    ComoResidencesMaterialBoards3,
                                    ComoResidencesMaterialBoards4,
                                    ComoResidencesMaterialBoards5,
                                    ComoResidencesMaterialBoards6,
                                    ComoResidencesMaterialBoards7,
                                    ComoResidencesMaterialBoards8,
                                    ComoResidencesMaterialBoards9,
                                    ComoResidencesMaterialBoards10,
                                    ComoResidencesMaterialBoards11,
                                    ComoResidencesMaterialBoards12,
                                    ComoResidencesMaterialBoards13,
                                    ComoResidencesMaterialBoards14,
                                    ComoResidencesMaterialBoards15,
                                    ComoResidencesMaterialBoards16,
                                    ComoResidencesMaterialBoards17,
                                    ComoResidencesMaterialBoards18,
                                    ComoResidencesMaterialBoards19,
                                    ComoResidencesMaterialBoards20,
                                    ComoResidencesMaterialBoards21,
                                    ComoResidencesMaterialBoards22,
                                    ComoResidencesMaterialBoards23,
                                    ComoResidencesMaterialBoards24,
                                    ComoResidencesMaterialBoards25,
                                    ComoResidencesMaterialBoards26,
                                    ComoResidencesMaterialBoards27,
                                    ComoResidencesMaterialBoards28,
                                    ComoResidencesMaterialBoards29,
                                    ComoResidencesMaterialBoards30,
                                    ComoResidencesMaterialBoards31,
                                    ComoResidencesMaterialBoards32,
                                    ComoResidencesMaterialBoards33,
                                    ComoResidencesMaterialBoards34,
                                    ComoResidencesMaterialBoards35,
                                    ComoResidencesMaterialBoards36,
                                    ComoResidencesMaterialBoards37,
                                    ComoResidencesMaterialBoards38,
                                    ComoResidencesMaterialBoards39,
                                    ComoResidencesMaterialBoards40,
                                    ComoResidencesMaterialBoards41,
                                    ComoResidencesMaterialBoards42,
                                    ComoResidencesMaterialBoards43,
                                    ComoResidencesMaterialBoards44,
                                    ComoResidencesMaterialBoards45,
                                    ComoResidencesMaterialBoards46,
                                    ComoResidencesMaterialBoards47,
                                    ComoResidencesMaterialBoards48,
                                    ComoResidencesMaterialBoards49,
                                    ComoResidencesMaterialBoards50,
                                    ComoResidencesMaterialBoards51,
                                    ComoResidencesMaterialBoards52,
                                    ComoResidencesMaterialBoards53,
                                    ComoResidencesMaterialBoards54,
                                    ComoResidencesMaterialBoards55,
                                    ComoResidencesMaterialBoards56,
                                    ComoResidencesMaterialBoards57,
                                    ComoResidencesMaterialBoards58,
                                    ComoResidencesMaterialBoards59,
                                    ComoResidencesMaterialBoards60,
                                    ComoResidencesMaterialBoards61,
                                    ComoResidencesMaterialBoards62,
                                    ComoResidencesMaterialBoards63,
                                    ComoResidencesMaterialBoards64,
                                    ComoResidencesMaterialBoards65,
                                    ComoResidencesMaterialBoards66,
                                    ComoResidencesMaterialBoards67,
                                    ComoResidencesMaterialBoards68,
                                    ComoResidencesMaterialBoards69,
                                    ComoResidencesMaterialBoards70,
                                    ComoResidencesMaterialBoards71,
                                    ComoResidencesMaterialBoards72,
                                    ComoResidencesMaterialBoards73,
                                    ComoResidencesMaterialBoards74,
                                    ComoResidencesMaterialBoards75,
                                    ComoResidencesMaterialBoards76,
                                    ComoResidencesMaterialBoards77,
                                    ComoResidencesMaterialBoards78,
                                    ComoResidencesMaterialBoards79,
                                    ComoResidencesMaterialBoards80,
                                    ComoResidencesMaterialBoards81,
                                    ComoResidencesMaterialBoards82,
                                    ComoResidencesMaterialBoards83,
                                    ComoResidencesMaterialBoards84,
                                    ComoResidencesMaterialBoards85,
                                    ComoResidencesMaterialBoards86
                                ]
                            }]
                        },
                    ]
                },
            ]
        }
    },
    {
        id: 'greenwood',
        checkIsInGroup: true,
        groupName: ['nakheel', 'plots'],
        zoomLevel: null,
        coords: {lat: 25.14225, lng: 55.406722},
        polygonCoords: [],
        icon: GreenwoodMark,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: {
            title: 'Greenwood',
            previews: [
                Greenwood1,
                Greenwood2,
                Greenwood3,
                Greenwood4
            ],
            desc: 'Greenwood, no Dubai, é um empreendimento urbano visionário que engloba inovação, sustentabilidade e luxo no coração desta cidade dinâmica. Este oásis extenso, cuidadosamente concebido para se harmonizar com a paisagem desértica circundante, redefine a vida moderna através de uma mistura de arquitetura de vanguarda, espaços verdes e comodidades topo de gama.',
            video: GreenwoodVideo,
            buttons: [
                {
                    type: "double",
                    data: [
                        {
                            type: 'img',
                            text: "Brochura",
                            data: [{
                                file: [
                                    GreenwoodBrochure1,
                                    GreenwoodBrochure2,
                                    GreenwoodBrochure3,
                                    GreenwoodBrochure4,
                                    GreenwoodBrochure5,
                                    GreenwoodBrochure6,
                                    GreenwoodBrochure7,
                                    GreenwoodBrochure8,
                                    GreenwoodBrochure9,
                                    GreenwoodBrochure10,
                                    GreenwoodBrochure11,
                                    GreenwoodBrochure12,
                                    GreenwoodBrochure13,
                                    GreenwoodBrochure14,
                                    GreenwoodBrochure15
                                ]
                            }]
                        },
                        {
                            type: 'img',
                            text: "Plano",
                            data: [{file: [GreenwoodMasterplan]}]
                        }
                    ]
                }
            ]
        }
    },
    {
        id: 'rixos',
        checkIsInGroup: true,
        groupName: ['nakheel', 'apartments_villas'],
        zoomLevel: null,
        coords: {lat: 25.30139936, lng: 55.31203838},
        polygonCoords: [],
        icon: RixosMark,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: {
            title: 'Rixos Dubai Islands Hotel & Residences',
            previews: [
                Rixos1,
                Rixos2,
                Rixos3,
                Rixos4,
                Rixos5
            ],
            desc: 'O Rixos Dubai Islands Hotel & Residences é um empreendimento de luxo que oferece residências à beira-mar nas Ilhas do Dubai. Entre as residências disponíveis encontram-se apartamentos com 1-3 quartos, casas de praia com 2-3 quartos e uma coleção limitada de moradias independentes. As comodidades residenciais, os serviços centrais e os serviços à la carte do Rixos Dubai Islands Hotel & Residences by Nakheel permitir-lhe-ão desfrutar de uma vida de resort.',
            link: 'https://rixos-landing.webflow.io/emotions/main-page',
            video: RixosVideo,
            buttons: [
                {
                    type: "double",
                    data: [
                        {
                            type: 'img',
                            text: "Brochura",
                            data: [
                                {file: [
                                    RixosBrochure1,
                                    RixosBrochure2,
                                    RixosBrochure3,
                                    RixosBrochure4,
                                    RixosBrochure5,
                                    RixosBrochure6,
                                    RixosBrochure7,
                                    RixosBrochure8,
                                    RixosBrochure9,
                                    RixosBrochure10,
                                    RixosBrochure11,
                                    RixosBrochure12,
                                    RixosBrochure13,
                                    RixosBrochure14,
                                    RixosBrochure15
                                ]}
                            ]
                        },
                        {
                            type: 'img',
                            text: "Planos de piso",
                            data: [
                                {
                                    title: 'A1',
                                    file: [
                                        RixosFloorPlansA1_1,
                                        RixosFloorPlansA1_2,
                                        RixosFloorPlansA1_3,
                                        RixosFloorPlansA1_4,
                                        RixosFloorPlansA1_5,
                                        RixosFloorPlansA1_6,
                                        RixosFloorPlansA1_7,
                                        RixosFloorPlansA1_8,
                                        RixosFloorPlansA1_9,
                                        RixosFloorPlansA1_10,
                                        RixosFloorPlansA1_11,
                                        RixosFloorPlansA1_12,
                                        RixosFloorPlansA1_13,
                                        RixosFloorPlansA1_14,
                                        RixosFloorPlansA1_15
                                    ]
                                },
                                {
                                    title: 'A2',
                                    file: [
                                        RixosFloorPlansA2_1,
                                        RixosFloorPlansA2_2,
                                        RixosFloorPlansA2_3,
                                        RixosFloorPlansA2_4,
                                        RixosFloorPlansA2_5,
                                        RixosFloorPlansA2_6,
                                        RixosFloorPlansA2_7,
                                        RixosFloorPlansA2_8,
                                        RixosFloorPlansA2_9,
                                        RixosFloorPlansA2_10,
                                        RixosFloorPlansA2_11,
                                        RixosFloorPlansA2_12,
                                        RixosFloorPlansA2_13,
                                        RixosFloorPlansA2_14,
                                        RixosFloorPlansA2_15
                                    ]
                                },
                                {
                                    title: 'A3',
                                    file: [
                                        RixosFloorPlansA3_1,
                                        RixosFloorPlansA3_2,
                                        RixosFloorPlansA3_3,
                                        RixosFloorPlansA3_4,
                                        RixosFloorPlansA3_5,
                                        RixosFloorPlansA3_6,
                                        RixosFloorPlansA3_7,
                                        RixosFloorPlansA3_8,
                                        RixosFloorPlansA3_9,
                                        RixosFloorPlansA3_10,
                                        RixosFloorPlansA3_11,
                                        RixosFloorPlansA3_12,
                                        RixosFloorPlansA3_13,
                                        RixosFloorPlansA3_14,
                                        RixosFloorPlansA3_15
                                    ]
                                },
                                {
                                    title: 'A4',
                                    file: [
                                        RixosFloorPlansA4_1,
                                        RixosFloorPlansA4_2,
                                        RixosFloorPlansA4_3,
                                        RixosFloorPlansA4_4,
                                        RixosFloorPlansA4_5,
                                        RixosFloorPlansA4_6,
                                        RixosFloorPlansA4_7,
                                        RixosFloorPlansA4_8,
                                        RixosFloorPlansA4_9,
                                        RixosFloorPlansA4_10,
                                        RixosFloorPlansA4_11,
                                        RixosFloorPlansA4_12,
                                        RixosFloorPlansA4_13,
                                        RixosFloorPlansA4_14,
                                        RixosFloorPlansA4_15
                                    ]
                                },
                                {
                                    title: "Beach Houses",
                                    file: [
                                        RixosFloorPlansBeachHouses1,
                                        RixosFloorPlansBeachHouses2,
                                        RixosFloorPlansBeachHouses3,
                                        RixosFloorPlansBeachHouses4,
                                        RixosFloorPlansBeachHouses5,
                                        RixosFloorPlansBeachHouses6,
                                        RixosFloorPlansBeachHouses7,
                                        RixosFloorPlansBeachHouses8,
                                        RixosFloorPlansBeachHouses9,
                                        RixosFloorPlansBeachHouses10,
                                        RixosFloorPlansBeachHouses11,
                                        RixosFloorPlansBeachHouses12,
                                        RixosFloorPlansBeachHouses13,
                                        RixosFloorPlansBeachHouses14,
                                        RixosFloorPlansBeachHouses15
                                    ]
                                },
                                {
                                    title: "Villas",
                                    file: [
                                        RixosFloorPlansVillas1,
                                        RixosFloorPlansVillas2,
                                        RixosFloorPlansVillas3,
                                        RixosFloorPlansVillas4,
                                        RixosFloorPlansVillas5,
                                        RixosFloorPlansVillas6,
                                        RixosFloorPlansVillas7,
                                        RixosFloorPlansVillas8,
                                        RixosFloorPlansVillas9,
                                        RixosFloorPlansVillas10,
                                        RixosFloorPlansVillas11,
                                        RixosFloorPlansVillas12,
                                        RixosFloorPlansVillas13,
                                        RixosFloorPlansVillas14,
                                        RixosFloorPlansVillas15
                                    ]
                                },
                            ]
                        }
                    ]
                },
                {
                    type: "double",
                    data: [
                        {
                            type: 'img',
                            text: "Folha de informação",
                            data: [
                                {file: [
                                    RixosFactSheet1,
                                    RixosFactSheet2,
                                    RixosFactSheet3,
                                    RixosFactSheet4,
                                    RixosFactSheet5,
                                    RixosFactSheet6,
                                    RixosFactSheet7,
                                    RixosFactSheet8,
                                    RixosFactSheet9,
                                    RixosFactSheet10,
                                    RixosFactSheet11,
                                    RixosFactSheet12,
                                    RixosFactSheet13,
                                    RixosFactSheet14,
                                    RixosFactSheet15
                                ]}
                            ]
                        },
                        {
                            type: 'img',
                            text: "Plano de pagamento",
                            data: [
                                {file: [RixosPaymentPlan]}
                            ]
                        }
                    ]
                }
            ]
        }
    },
    {
        id: 'district-one-west-2',
        checkIsInGroup: true,
        groupName: ['nakheel', 'villas'],
        zoomLevel: null,
        coords: {lat: 25.14489796, lng: 55.27764592},
        polygonCoords: [],
        icon: DistrictOneMark,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: {
            title: 'District One West',
            previews: [
                DistrictOneWestPreview1,
                DistrictOneWestPreview2,
                DistrictOneWestPreview5,
                DistrictOneWestPreview6
            ],
            desc: 'O estilo de vida épico do District One West na MBR City, Dubai, é apresentado pela Nakheel para inspirar uma nova era de desenvolvimento, oferecendo moradias com 4 e 5 quartos e mansões com 6 e 7 quartos. Venha descobrir por si mesmo o nível mais elevado de luxo numa das comunidades mais prestigiadas do Dubai. Experimente o estilo de vida extraordinário num sublime condomínio fechado com uma coleção épica de propriedades impecáveis e casas de primeira qualidade para os residentes.',
            video: DistrictOneVideo,
            buttons: [
                {
                    type: "double",
                    data: [
                        {
                            type: 'img',
                            text: "Brochura",
                            data: [{
                                file: [
                                    DistrictOneBrochure1,
                                    DistrictOneBrochure2,
                                    DistrictOneBrochure3,
                                    DistrictOneBrochure4,
                                    DistrictOneBrochure5,
                                    DistrictOneBrochure6,
                                    DistrictOneBrochure7,
                                    DistrictOneBrochure8,
                                    DistrictOneBrochure9,
                                    DistrictOneBrochure10,
                                    DistrictOneBrochure11,
                                    DistrictOneBrochure12,
                                    DistrictOneBrochure13,
                                    DistrictOneBrochure14,
                                    DistrictOneBrochure15
                                ]
                            }]
                        },
                        {
                            type: 'img',
                            text: "Planos de piso",
                            data: [{
                                file: [
                                    DistrictOneFloorPlans1,
                                    DistrictOneFloorPlans2,
                                    DistrictOneFloorPlans3,
                                    DistrictOneFloorPlans4,
                                    DistrictOneFloorPlans5,
                                    DistrictOneFloorPlans6,
                                    DistrictOneFloorPlans7,
                                    DistrictOneFloorPlans8,
                                    DistrictOneFloorPlans9,
                                    DistrictOneFloorPlans10,
                                    DistrictOneFloorPlans11,
                                    DistrictOneFloorPlans12,
                                    DistrictOneFloorPlans13,
                                    DistrictOneFloorPlans14,
                                    DistrictOneFloorPlans15
                                ]
                            }]
                        }
                    ]
                },
                {
                    type: "single",
                    data: [
                        {
                            type: 'img',
                            text: "Plano",
                            data: [{file: [DistrictOneMasterplan]}]
                        }
                    ]
                },
                {
                    type: "single",
                    data: [
                        {
                            type: 'img',
                            text: "Brochura do quadro de material",
                            data: [{
                                file: [
                                    DistrictOneMaterialBoardBrochure1,
                                    DistrictOneMaterialBoardBrochure2,
                                    DistrictOneMaterialBoardBrochure3,
                                    DistrictOneMaterialBoardBrochure4,
                                    DistrictOneMaterialBoardBrochure5,
                                    DistrictOneMaterialBoardBrochure6,
                                    DistrictOneMaterialBoardBrochure7,
                                    DistrictOneMaterialBoardBrochure8,
                                    DistrictOneMaterialBoardBrochure9,
                                    DistrictOneMaterialBoardBrochure10,
                                    DistrictOneMaterialBoardBrochure11,
                                    DistrictOneMaterialBoardBrochure12
                                ]
                            }]
                        }
                    ]
                }
            ]
        }
    },
    {
        id: 'the_palm_tower_residences',
        checkIsInGroup: true,
        groupName: ['nakheel', 'apartments'],
        zoomLevel: 12,
        coords: {lat: 25.1149504481807, lng: 55.13787513591},
        polygonCoords: [],
        icon: MarkThePalmTowerResidences,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'st_regis_dubai',
        checkIsInGroup: true,
        groupName: ['nakheel', 'apartments'],
        zoomLevel: 12.5,
        coords: {lat: 25.1131661007271, lng: 55.1418165472705},
        polygonCoords: [],
        icon: MarkStRegisDubaiThePalm,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'palm_beach_towers',
        checkIsInGroup: true,
        groupName: ['nakheel', 'apartments'],
        zoomLevel: 12,
        coords: {lat: 25.1017416800526, lng: 55.1549168967729},
        polygonCoords: [],
        icon: MarkPalmBeachTowers,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: {
            title: 'Palm Beach Towers',
            previews: [
                PalmBeachTowersPreview1,
                PalmBeachTowersPreview2,
                PalmBeachTowersPreview3,
                PalmBeachTowersPreview4,
                PalmBeachTowersPreview5,
                PalmBeachTowersPreview6,
                PalmBeachTowersPreview7,
                PalmBeachTowersPreview8,
                PalmBeachTowersPreview9,
                PalmBeachTowersPreview10,
                PalmBeachTowersPreview11,
                PalmBeachTowersPreview12,
                PalmBeachTowersPreview13,
                PalmBeachTowersPreview14,
                PalmBeachTowersPreview15,
                PalmBeachTowersPreview16,
                PalmBeachTowersPreview17,
            ],
            desc: '',
            link: 'https://www.palmbeachtowers.ae',
            video: PalmBeachTowersVideo,
            buttons: [
                {
                    type: "single",
                    data: [
                        {
                            type: 'img',
                            text: "Planos de piso",
                            data: [
                                {
                                    title: "Torre 1",
                                    file: [
                                        PalmBeachTowersFloorPlans1_1,
                                        PalmBeachTowersFloorPlans1_2,
                                        PalmBeachTowersFloorPlans1_3,
                                        PalmBeachTowersFloorPlans1_4,
                                        PalmBeachTowersFloorPlans1_5,
                                        PalmBeachTowersFloorPlans1_6,
                                        PalmBeachTowersFloorPlans1_7,
                                        PalmBeachTowersFloorPlans1_8,
                                        PalmBeachTowersFloorPlans1_9,
                                        PalmBeachTowersFloorPlans1_10,
                                        PalmBeachTowersFloorPlans1_11,
                                        PalmBeachTowersFloorPlans1_12,
                                        PalmBeachTowersFloorPlans1_13,
                                        PalmBeachTowersFloorPlans1_14,
                                        PalmBeachTowersFloorPlans1_15
                                    ]
                                },
                                {
                                    title: "Torre 2",
                                    file: [
                                        PalmBeachTowersFloorPlans2_1,
                                        PalmBeachTowersFloorPlans2_2,
                                        PalmBeachTowersFloorPlans2_3,
                                        PalmBeachTowersFloorPlans2_4,
                                        PalmBeachTowersFloorPlans2_5,
                                        PalmBeachTowersFloorPlans2_6,
                                        PalmBeachTowersFloorPlans2_7,
                                        PalmBeachTowersFloorPlans2_8,
                                        PalmBeachTowersFloorPlans2_9,
                                        PalmBeachTowersFloorPlans2_10,
                                        PalmBeachTowersFloorPlans2_11,
                                        PalmBeachTowersFloorPlans2_12,
                                        PalmBeachTowersFloorPlans2_13,
                                        PalmBeachTowersFloorPlans2_14,
                                        PalmBeachTowersFloorPlans2_15
                                    ]
                                },
                                {
                                    title: "Torre 3",
                                    file: [
                                        PalmBeachTowersFloorPlans3_1,
                                        PalmBeachTowersFloorPlans3_2,
                                        PalmBeachTowersFloorPlans3_3,
                                        PalmBeachTowersFloorPlans3_4,
                                        PalmBeachTowersFloorPlans3_5,
                                        PalmBeachTowersFloorPlans3_6,
                                        PalmBeachTowersFloorPlans3_7,
                                        PalmBeachTowersFloorPlans3_8,
                                        PalmBeachTowersFloorPlans3_9,
                                        PalmBeachTowersFloorPlans3_10,
                                        PalmBeachTowersFloorPlans3_11,
                                        PalmBeachTowersFloorPlans3_12,
                                        PalmBeachTowersFloorPlans3_13,
                                        PalmBeachTowersFloorPlans3_14,
                                        PalmBeachTowersFloorPlans3_15
                                    ]
                                },
                            ]
                        }
                    ]
                },
                {
                    type: "single",
                    data: [
                        {
                            type: 'img',
                            text: "Brochura",
                            data: [{
                                file: [
                                    PalmBeachTowersBrochure1,
                                    PalmBeachTowersBrochure2,
                                    PalmBeachTowersBrochure3,
                                    PalmBeachTowersBrochure4,
                                    PalmBeachTowersBrochure5,
                                    PalmBeachTowersBrochure6,
                                    PalmBeachTowersBrochure7,
                                    PalmBeachTowersBrochure8,
                                    PalmBeachTowersBrochure9,
                                    PalmBeachTowersBrochure10,
                                    PalmBeachTowersBrochure11,
                                    PalmBeachTowersBrochure12,
                                    PalmBeachTowersBrochure13,
                                    PalmBeachTowersBrochure14,
                                    PalmBeachTowersBrochure15,
                                    PalmBeachTowersBrochure16,
                                    PalmBeachTowersBrochure17,
                                    PalmBeachTowersBrochure18
                                ]
                            }]
                        }
                    ]
                }
            ]
        }
    },
    {
        id: 'the_world',
        checkIsInGroup: true,
        groupName: ['nakheel', 'apartments'],
        zoomLevel: null,
        coords: {lat: 25.2235063463483, lng: 55.1696737430049},
        polygonCoords: [
            the_world_pol_1,
            the_world_pol_2,
            the_world_pol_3,
            the_world_pol_4,
            the_world_pol_5,
            the_world_pol_6,
            the_world_pol_7,
            the_world_pol_8,
            the_world_pol_9,
            the_world_pol_10,
            the_world_pol_11,
            the_world_pol_12,
            the_world_pol_13,
            the_world_pol_14,
            the_world_pol_15,
            the_world_pol_16,
            the_world_pol_17,
            the_world_pol_18,
            the_world_pol_19,
            the_world_pol_20,
            the_world_pol_21,
            the_world_pol_22,
            the_world_pol_23,
            the_world_pol_24,
            the_world_pol_25,
            the_world_pol_26,
            the_world_pol_27,
            the_world_pol_28,
            the_world_pol_29,
            the_world_pol_30,
            the_world_pol_31,
            the_world_pol_32,
            the_world_pol_33,
            the_world_pol_34,
            the_world_pol_35,
            the_world_pol_36,
            the_world_pol_37,
            the_world_pol_38,
            the_world_pol_39,
            the_world_pol_40,
            the_world_pol_41,
            the_world_pol_42,
            the_world_pol_43,
            the_world_pol_44,
            the_world_pol_45,
            the_world_pol_46,
            the_world_pol_47,
            the_world_pol_48,
            the_world_pol_49,
            the_world_pol_50,
            the_world_pol_51,
            the_world_pol_52,
            the_world_pol_53,
            the_world_pol_54,
            the_world_pol_55,
            the_world_pol_56,
            the_world_pol_57,
            the_world_pol_58,
            the_world_pol_59,
            the_world_pol_60,
            the_world_pol_61,
            the_world_pol_62,
            the_world_pol_63,
            the_world_pol_64,
            the_world_pol_65,
            the_world_pol_66,
            the_world_pol_67,
            the_world_pol_68,
            the_world_pol_69,
            the_world_pol_70,
            the_world_pol_71,
            the_world_pol_72,
            the_world_pol_73,
            the_world_pol_74,
            the_world_pol_75,
            the_world_pol_76,
            the_world_pol_77,
            the_world_pol_78,
            the_world_pol_79,
            the_world_pol_80,
            the_world_pol_81,
            the_world_pol_82,
            the_world_pol_83,
            the_world_pol_84,
            the_world_pol_85,
            the_world_pol_86,
            the_world_pol_87,
            the_world_pol_88,
            the_world_pol_89,
            the_world_pol_90,
            the_world_pol_91,
            the_world_pol_92,
            the_world_pol_93,
            the_world_pol_94,
            the_world_pol_95,
            the_world_pol_96,
            the_world_pol_97,
            the_world_pol_98,
            the_world_pol_99,
            the_world_pol_100,
            the_world_pol_101,
            the_world_pol_102,
            the_world_pol_103,
            the_world_pol_104,
            the_world_pol_105,
            the_world_pol_106,
            the_world_pol_107,
            the_world_pol_108,
            the_world_pol_109,
            the_world_pol_110,
            the_world_pol_111,
            the_world_pol_112,
            the_world_pol_113,
            the_world_pol_114,
            the_world_pol_115,
            the_world_pol_116,
            the_world_pol_117,
            the_world_pol_118,
            the_world_pol_119,
            the_world_pol_120,
            the_world_pol_121,
            the_world_pol_122,
            the_world_pol_123,
            the_world_pol_124,
            the_world_pol_125,
            the_world_pol_126,
            the_world_pol_127,
            the_world_pol_128,
            the_world_pol_129,
            the_world_pol_130,
            the_world_pol_131,
            the_world_pol_132,
            the_world_pol_133,
            the_world_pol_134,
            the_world_pol_135,
            the_world_pol_136,
            the_world_pol_137,
            the_world_pol_138,
            the_world_pol_139,
            the_world_pol_140,
            the_world_pol_141,
            the_world_pol_142,
            the_world_pol_143,
            the_world_pol_144,
            the_world_pol_145,
            the_world_pol_146,
            the_world_pol_147,
            the_world_pol_148,
            the_world_pol_149,
            the_world_pol_150,
            the_world_pol_151,
            the_world_pol_152,
            the_world_pol_153,
            the_world_pol_154,
            the_world_pol_155,
            the_world_pol_156,
            the_world_pol_157,
            the_world_pol_158,
            the_world_pol_159,
            the_world_pol_160,
            the_world_pol_161,
            the_world_pol_162,
            the_world_pol_163,
            the_world_pol_164,
            the_world_pol_165,
            the_world_pol_166,
            the_world_pol_167,
            the_world_pol_168,
            the_world_pol_169,
            the_world_pol_170,
            the_world_pol_171,
            the_world_pol_172,
            the_world_pol_173,
            the_world_pol_174,
            the_world_pol_175,
            the_world_pol_176,
            the_world_pol_177,
            the_world_pol_178,
            the_world_pol_179,
            the_world_pol_180,
            the_world_pol_181,
            the_world_pol_182,
            the_world_pol_183,
            the_world_pol_184,
            the_world_pol_185,
            the_world_pol_186,
            the_world_pol_187,
            the_world_pol_188,
            the_world_pol_189,
            the_world_pol_190,
            the_world_pol_191,
            the_world_pol_192,
            the_world_pol_193,
            the_world_pol_194,
            the_world_pol_195,
            the_world_pol_196,
            the_world_pol_197,
            the_world_pol_198,
            the_world_pol_199,
            the_world_pol_200,
            the_world_pol_201,
            the_world_pol_202,
            the_world_pol_203,
            the_world_pol_204,
        ],
        icon: MarkTheWorld,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'nad_al_sheba_villas',
        checkIsInGroup: true,
        groupName: ['nakheel', 'apartments'],
        zoomLevel: null,
        coords: {lat: 25.1601657220897, lng: 55.3718879480575},
        polygonCoords: [],
        icon: MarkNadAlShebaVillas,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'warsan_village',
        checkIsInGroup: true,
        groupName: ['nakheel', 'apartments'],
        zoomLevel: null,
        coords: {lat: 25.1555223698421, lng: 55.4050612441932},
        polygonCoords: [],
        icon: MarkWarsanVillage,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'international_city',
        checkIsInGroup: true,
        groupName: ['nakheel', 'apartments'],
        zoomLevel: null,
        coords: {lat: 25.1647181646089, lng: 55.4080603547548},
        polygonCoords: [
            international_city_1,
            international_city_2
        ],
        icon: MarkInternationalCity,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'canal_front_residences',
        checkIsInGroup: true,
        groupName: ['meydan', 'apartments'],
        zoomLevel: null,
        coords: {lat: 25.185830988435, lng: 55.248199985770604},
        polygonCoords: [canal_front_residences],
        icon: MarkCanalFrontResidences,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: {
            title: 'Residências em frente ao canal',
            previews: [
                CanalFrontResidencesPreview1,
                CanalFrontResidencesPreview2,
                CanalFrontResidencesPreview3,
                CanalFrontResidencesPreview4,
                CanalFrontResidencesPreview5,
            ],
            desc: '',
            link: 'https://www.canalfrontresidences.meydan.ae/',
            video: CanalFrontResidencesVideo,
            buttons: [
                {
                    type: "double",
                    data: [
                        {
                            type: 'img',
                            text: "Brochura",
                            data: [{
                                file: [
                                    CanalFrontResidencesBrochure1,
                                    CanalFrontResidencesBrochure2,
                                    CanalFrontResidencesBrochure3,
                                    CanalFrontResidencesBrochure4,
                                    CanalFrontResidencesBrochure5,
                                    CanalFrontResidencesBrochure6,
                                    CanalFrontResidencesBrochure7,
                                    CanalFrontResidencesBrochure8,
                                    CanalFrontResidencesBrochure9,
                                    CanalFrontResidencesBrochure10,
                                    CanalFrontResidencesBrochure11,
                                    CanalFrontResidencesBrochure12,
                                    CanalFrontResidencesBrochure13,
                                    CanalFrontResidencesBrochure14,
                                    CanalFrontResidencesBrochure15
                                ]
                            }]
                        },
                        {
                            type: 'img',
                            text: "Planos de piso",
                            data: [{
                                file: [
                                    CanalFrontResidencesFloorPlans1,
                                    CanalFrontResidencesFloorPlans2,
                                    CanalFrontResidencesFloorPlans3,
                                    CanalFrontResidencesFloorPlans4,
                                    CanalFrontResidencesFloorPlans5,
                                    CanalFrontResidencesFloorPlans6,
                                    CanalFrontResidencesFloorPlans7,
                                    CanalFrontResidencesFloorPlans8,
                                    CanalFrontResidencesFloorPlans9,
                                    CanalFrontResidencesFloorPlans10,
                                    CanalFrontResidencesFloorPlans11,
                                    CanalFrontResidencesFloorPlans12,
                                    CanalFrontResidencesFloorPlans13,
                                    CanalFrontResidencesFloorPlans14,
                                    CanalFrontResidencesFloorPlans15
                                ]
                            }]
                        }
                    ]
                },
                {
                    type: "double",
                    data: [
                        {
                            type: 'img',
                            text: "Folha de informação",
                            data: [{
                                file: [
                                    CanalFrontResidencesFactSheet1,
                                    CanalFrontResidencesFactSheet2,
                                    CanalFrontResidencesFactSheet3,
                                    CanalFrontResidencesFactSheet4,
                                    CanalFrontResidencesFactSheet5
                                ]
                            }]
                        },
                        {
                            type: 'img',
                            text: "Plano de pagamento",
                            data: [{file: [CanalFrontResidencesPaymentPlan]}]
                        },
                    ]
                }
            ]
        }
    },
    {
        id: 'district_one',
        checkIsInGroup: true,
        groupName: ['meydan', 'plots'],
        zoomLevel: null,
        coords: {lat: 25.1664659277986, lng: 55.2772011690859},
        polygonCoords: [district_one],
        icon: MarkDistrictOne,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    // {
    //     id: 'district_one_west1',
    //     checkIsInGroup: true,
    //     groupName: ['meydan', 'plots'],
    //     zoomLevel: 12,
    //     coords: {lat: 25.1452064907647, lng: 55.2776460317349},
    //     polygonCoords: null,
    //     icon: MarkDistrictOneWest,
    //     activeIcon: null,
    //     clickable: {
    //         marker: true,
    //         polygon: true
    //     },
    //     content: null
    // },
    {
        id: 'golf_course',
        checkIsInGroup: true,
        groupName: ['meydan', 'villas'],
        zoomLevel: null,
        coords: {lat: 25.166213527711, lng: 55.3134315968028},
        polygonCoords: null,
        icon: MarkGolfCourse,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'polo_residence',
        checkIsInGroup: true,
        groupName: ['meydan', 'villas'],
        zoomLevel: 12,
        coords: {lat: 25.1527592544695, lng: 55.2948257942159},
        polygonCoords: null,
        icon: MarkPoloResidence,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'meydan_hotel',
        checkIsInGroup: true,
        groupName: ['meydan', 'villas'],
        zoomLevel: null,
        coords: {lat: 25.1596989530687, lng: 55.3035742049431},
        polygonCoords: null,
        icon: MarkMeydanHotel,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: null
    },
    {
        id: 'meydan_avenue',
        checkIsInGroup: true,
        groupName: ['meydan', 'plots'],
        zoomLevel: 12,
        coords: {lat: 25.1578386088726, lng: 55.2945025159534},
        polygonCoords: null,
        icon: MarkMeydanAvenue,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: {
            title: 'Meydan Avenue',
            previews: [
                MeydanAvenuePreview1,
                MeydanAvenuePreview2,
                MeydanAvenuePreview3,
                MeydanAvenuePreview4,
                MeydanAvenuePreview5,
                MeydanAvenuePreview6,
            ],
            desc: '',
            link: null,
            video: null,
            buttons: [
                {
                    type: "single",
                    data: [
                        {
                            type: 'img',
                            text: "Lote A",
                            data: [
                                {
                                    title: "Design de lote",
                                    file: [
                                        MeydanAvenuePlotAPlotDesign1,
                                        MeydanAvenuePlotAPlotDesign2,
                                        MeydanAvenuePlotAPlotDesign3,
                                        MeydanAvenuePlotAPlotDesign4,
                                        MeydanAvenuePlotAPlotDesign5,
                                        MeydanAvenuePlotAPlotDesign6,
                                        MeydanAvenuePlotAPlotDesign7,
                                        MeydanAvenuePlotAPlotDesign8,
                                        MeydanAvenuePlotAPlotDesign9,
                                        MeydanAvenuePlotAPlotDesign10,
                                        MeydanAvenuePlotAPlotDesign11,
                                        MeydanAvenuePlotAPlotDesign12,
                                        MeydanAvenuePlotAPlotDesign13,
                                        MeydanAvenuePlotAPlotDesign14,
                                        MeydanAvenuePlotAPlotDesign15
                                    ]
                                },
                                {
                                    title: "Controlo de desenvolvimento",
                                    file: [
                                        MeydanAvenuePlotADevControl1,
                                        MeydanAvenuePlotADevControl2,
                                        MeydanAvenuePlotADevControl3,
                                        MeydanAvenuePlotADevControl4,
                                        MeydanAvenuePlotADevControl5,
                                        MeydanAvenuePlotADevControl6,
                                        MeydanAvenuePlotADevControl7,
                                        MeydanAvenuePlotADevControl8,
                                        MeydanAvenuePlotADevControl9,
                                        MeydanAvenuePlotADevControl10,
                                        MeydanAvenuePlotADevControl11,
                                        MeydanAvenuePlotADevControl12,
                                        MeydanAvenuePlotADevControl13,
                                        MeydanAvenuePlotADevControl14,
                                        MeydanAvenuePlotADevControl15
                                    ]
                                },
                                {
                                    title: "Brochura",
                                    file: [
                                        MeydanAvenuePlotABrochure1,
                                        MeydanAvenuePlotABrochure2,
                                        MeydanAvenuePlotABrochure3,
                                        MeydanAvenuePlotABrochure4,
                                        MeydanAvenuePlotABrochure5,
                                        MeydanAvenuePlotABrochure6,
                                        MeydanAvenuePlotABrochure7,
                                        MeydanAvenuePlotABrochure8,
                                        MeydanAvenuePlotABrochure9,
                                        MeydanAvenuePlotABrochure10,
                                        MeydanAvenuePlotABrochure11,
                                        MeydanAvenuePlotABrochure12,
                                        MeydanAvenuePlotABrochure13,
                                        MeydanAvenuePlotABrochure14,
                                        MeydanAvenuePlotABrochure15
                                    ]
                                },
                            ]
                        }
                    ]
                },
                {
                    type: "single",
                    data: [
                        {
                            type: 'img',
                            text: "Lote B",
                            data: [
                                {
                                    title: "Design de lote",
                                    file: [
                                        MeydanAvenuePlotBPlotDesign1,
                                        MeydanAvenuePlotBPlotDesign2,
                                        MeydanAvenuePlotBPlotDesign3,
                                        MeydanAvenuePlotBPlotDesign4,
                                        MeydanAvenuePlotBPlotDesign5,
                                        MeydanAvenuePlotBPlotDesign6,
                                        MeydanAvenuePlotBPlotDesign7,
                                        MeydanAvenuePlotBPlotDesign8,
                                        MeydanAvenuePlotBPlotDesign9,
                                        MeydanAvenuePlotBPlotDesign10,
                                        MeydanAvenuePlotBPlotDesign11,
                                        MeydanAvenuePlotBPlotDesign12,
                                        MeydanAvenuePlotBPlotDesign13,
                                        MeydanAvenuePlotBPlotDesign14,
                                        MeydanAvenuePlotBPlotDesign15
                                    ]
                                },
                                {
                                    title: "Controlo de desenvolvimento",
                                    file: [
                                        MeydanAvenuePlotBDevControl1,
                                        MeydanAvenuePlotBDevControl2,
                                        MeydanAvenuePlotBDevControl3,
                                        MeydanAvenuePlotBDevControl4,
                                        MeydanAvenuePlotBDevControl5,
                                        MeydanAvenuePlotBDevControl6,
                                        MeydanAvenuePlotBDevControl7,
                                        MeydanAvenuePlotBDevControl8,
                                        MeydanAvenuePlotBDevControl9,
                                        MeydanAvenuePlotBDevControl10,
                                        MeydanAvenuePlotBDevControl11,
                                        MeydanAvenuePlotBDevControl12,
                                        MeydanAvenuePlotBDevControl13,
                                        MeydanAvenuePlotBDevControl14,
                                        MeydanAvenuePlotBDevControl15
                                    ]
                                },
                                {
                                    title: "Brochura",
                                    file: [
                                        MeydanAvenuePlotBBrochure1,
                                        MeydanAvenuePlotBBrochure2,
                                        MeydanAvenuePlotBBrochure3,
                                        MeydanAvenuePlotBBrochure4,
                                        MeydanAvenuePlotBBrochure5,
                                        MeydanAvenuePlotBBrochure6,
                                        MeydanAvenuePlotBBrochure7,
                                        MeydanAvenuePlotBBrochure8,
                                        MeydanAvenuePlotBBrochure9,
                                        MeydanAvenuePlotBBrochure10,
                                        MeydanAvenuePlotBBrochure11,
                                        MeydanAvenuePlotBBrochure12,
                                        MeydanAvenuePlotBBrochure13,
                                        MeydanAvenuePlotBBrochure14,
                                        MeydanAvenuePlotBBrochure15
                                    ]
                                },
                            ]
                        }
                    ]
                },
                {
                    type: "single",
                    data: [
                        {
                            type: 'img',
                            text: "Lote C",
                            data: [
                                {
                                    title: "Design de lote",
                                    file: [
                                        MeydanAvenuePlotCPlotDesign1,
                                        MeydanAvenuePlotCPlotDesign2,
                                        MeydanAvenuePlotCPlotDesign3,
                                        MeydanAvenuePlotCPlotDesign4,
                                        MeydanAvenuePlotCPlotDesign5,
                                        MeydanAvenuePlotCPlotDesign6,
                                        MeydanAvenuePlotCPlotDesign7,
                                        MeydanAvenuePlotCPlotDesign8,
                                        MeydanAvenuePlotCPlotDesign9,
                                        MeydanAvenuePlotCPlotDesign10,
                                        MeydanAvenuePlotCPlotDesign11,
                                        MeydanAvenuePlotCPlotDesign12,
                                        MeydanAvenuePlotCPlotDesign13,
                                        MeydanAvenuePlotCPlotDesign14,
                                        MeydanAvenuePlotCPlotDesign15
                                    ]
                                },
                                {
                                    title: "Controlo de desenvolvimento",
                                    file: [
                                        MeydanAvenuePlotCDevControl1,
                                        MeydanAvenuePlotCDevControl2,
                                        MeydanAvenuePlotCDevControl3,
                                        MeydanAvenuePlotCDevControl4,
                                        MeydanAvenuePlotCDevControl5,
                                        MeydanAvenuePlotCDevControl6,
                                        MeydanAvenuePlotCDevControl7,
                                        MeydanAvenuePlotCDevControl8,
                                        MeydanAvenuePlotCDevControl9,
                                        MeydanAvenuePlotCDevControl10,
                                        MeydanAvenuePlotCDevControl11,
                                        MeydanAvenuePlotCDevControl12,
                                        MeydanAvenuePlotCDevControl13,
                                        MeydanAvenuePlotCDevControl14,
                                        MeydanAvenuePlotCDevControl15
                                    ]
                                },
                                {
                                    title: "Brochura",
                                    file: [
                                        MeydanAvenuePlotCBrochure1,
                                        MeydanAvenuePlotCBrochure2,
                                        MeydanAvenuePlotCBrochure3,
                                        MeydanAvenuePlotCBrochure4,
                                        MeydanAvenuePlotCBrochure5,
                                        MeydanAvenuePlotCBrochure6,
                                        MeydanAvenuePlotCBrochure7,
                                        MeydanAvenuePlotCBrochure8,
                                        MeydanAvenuePlotCBrochure9,
                                        MeydanAvenuePlotCBrochure10,
                                        MeydanAvenuePlotCBrochure11,
                                        MeydanAvenuePlotCBrochure12,
                                        MeydanAvenuePlotCBrochure13,
                                        MeydanAvenuePlotCBrochure14,
                                        MeydanAvenuePlotCBrochure15
                                    ]
                                },
                            ]
                        }
                    ]
                }
            ]
        }
    },
    {
        id: 'district_11',
        checkIsInGroup: true,
        groupName: ['meydan', 'villas'],
        zoomLevel: null,
        coords: {lat: 25.125008726617, lng: 55.3520318687308},
        polygonCoords: [district_11],
        icon: MarkDistrict11,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: {
            title: 'District 11',
            previews: [
                District11Preview1,
                District11Preview2,
                District11Preview3,
                District11Preview4,
                District11Preview5,
                District11Preview6,
            ],
            desc: '',
            link: null,
            video: null,
            buttons: [
                {
                    type: "double",
                    data: [
                        {
                            type: 'img',
                            text: "Brochura",
                            data: [{
                                file: [
                                    District11Brochure1,
                                    District11Brochure2,
                                    District11Brochure3,
                                    District11Brochure4,
                                    District11Brochure5,
                                    District11Brochure6,
                                    District11Brochure7,
                                    District11Brochure8,
                                    District11Brochure9,
                                    District11Brochure10,
                                    District11Brochure11,
                                    District11Brochure12,
                                    District11Brochure13,
                                    District11Brochure14,
                                    District11Brochure15
                                ]
                            }]
                        },
                        {
                            type: 'img',
                            text: "Planos de piso",
                            data: [{
                                file: [
                                    District11FloorPlans1,
                                    District11FloorPlans2,
                                    District11FloorPlans3,
                                    District11FloorPlans4,
                                    District11FloorPlans5,
                                    District11FloorPlans6,
                                    District11FloorPlans7,
                                    District11FloorPlans8,
                                    District11FloorPlans9,
                                    District11FloorPlans10,
                                    District11FloorPlans11
                                ]
                            }]
                        }
                    ]
                },
                {
                    type: "single",
                    data: [
                        {
                            type: 'img',
                            text: "Folha de informação",
                            data: [{
                                file: [
                                    District11Factsheet1,
                                    District11Factsheet2,
                                    District11Factsheet3,
                                    District11Factsheet4
                                ]
                            }]
                        }
                    ]
                }
            ]
        }
    },
    {
        id: 'naya_residence',
        checkIsInGroup: true,
        groupName: ['meydan', 'apartments'],
        zoomLevel: null,
        coords: {lat: 25.16281754, lng: 55.28744163},
        polygonCoords: [],
        icon: NayaResidenceMark,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: true
        },
        content: {
            title: 'Naya Residence',
            previews: [
                NayaResidencePreview1,
                NayaResidencePreview2,
                NayaResidencePreview3,
                NayaResidencePreview4,
                NayaResidencePreview5,
                NayaResidencePreview6,
                NayaResidencePreview7,
                NayaResidencePreview8,
                NayaResidencePreview9,
                NayaResidencePreview10,
            ],
            desc: '',
            link: '',
            video: NayaResidenceVideo,
            buttons: [
                {
                    type: "double",
                    data: [
                        {
                            type: 'img',
                            text: "Planos de piso",
                            data: [
                                {
                                    title: "Naya 1 Floor",
                                    file: [
                                        NayaResidenceFloorPlansNaya1_1,
                                        NayaResidenceFloorPlansNaya1_2,
                                        NayaResidenceFloorPlansNaya1_3,
                                        NayaResidenceFloorPlansNaya1_4,
                                        NayaResidenceFloorPlansNaya1_5,
                                        NayaResidenceFloorPlansNaya1_6,
                                        NayaResidenceFloorPlansNaya1_7,
                                        NayaResidenceFloorPlansNaya1_8,
                                        NayaResidenceFloorPlansNaya1_9,
                                        NayaResidenceFloorPlansNaya1_10,
                                        NayaResidenceFloorPlansNaya1_11,
                                        NayaResidenceFloorPlansNaya1_12,
                                        NayaResidenceFloorPlansNaya1_13,
                                        NayaResidenceFloorPlansNaya1_14,
                                        NayaResidenceFloorPlansNaya1_15
                                    ]
                                },
                                {
                                    title: "Naya 2 Floor",
                                    file: [
                                        NayaResidenceFloorPlansNaya2_1,
                                        NayaResidenceFloorPlansNaya2_2,
                                        NayaResidenceFloorPlansNaya2_3,
                                        NayaResidenceFloorPlansNaya2_4,
                                        NayaResidenceFloorPlansNaya2_5,
                                        NayaResidenceFloorPlansNaya2_6,
                                        NayaResidenceFloorPlansNaya2_7,
                                        NayaResidenceFloorPlansNaya2_8,
                                        NayaResidenceFloorPlansNaya2_9,
                                        NayaResidenceFloorPlansNaya2_10,
                                        NayaResidenceFloorPlansNaya2_11,
                                        NayaResidenceFloorPlansNaya2_12,
                                        NayaResidenceFloorPlansNaya2_13,
                                        NayaResidenceFloorPlansNaya2_14,
                                        NayaResidenceFloorPlansNaya2_15
                                    ]
                                },
                                {
                                    title: "Naya 3 Floor",
                                    file: [
                                        NayaResidenceFloorPlansNaya3_1,
                                        NayaResidenceFloorPlansNaya3_2,
                                        NayaResidenceFloorPlansNaya3_3,
                                        NayaResidenceFloorPlansNaya3_4,
                                        NayaResidenceFloorPlansNaya3_5,
                                        NayaResidenceFloorPlansNaya3_6,
                                        NayaResidenceFloorPlansNaya3_7,
                                        NayaResidenceFloorPlansNaya3_8,
                                        NayaResidenceFloorPlansNaya3_9,
                                        NayaResidenceFloorPlansNaya3_10,
                                        NayaResidenceFloorPlansNaya3_11,
                                        NayaResidenceFloorPlansNaya3_12,
                                        NayaResidenceFloorPlansNaya3_13,
                                        NayaResidenceFloorPlansNaya3_14,
                                        NayaResidenceFloorPlansNaya3_15
                                    ]
                                },
                                {
                                    title: "Villas",
                                    file: [
                                        NayaResidenceFloorPlansNayaLagoonVillas1,
                                        NayaResidenceFloorPlansNayaLagoonVillas2,
                                        NayaResidenceFloorPlansNayaLagoonVillas3,
                                        NayaResidenceFloorPlansNayaLagoonVillas4,
                                        NayaResidenceFloorPlansNayaLagoonVillas5,
                                        NayaResidenceFloorPlansNayaLagoonVillas6,
                                        NayaResidenceFloorPlansNayaLagoonVillas7,
                                        NayaResidenceFloorPlansNayaLagoonVillas8,
                                        NayaResidenceFloorPlansNayaLagoonVillas9,
                                        NayaResidenceFloorPlansNayaLagoonVillas10,
                                        NayaResidenceFloorPlansNayaLagoonVillas11
                                    ]
                                },
                            ]
                        },
                        {
                            type: 'img',
                            text: "Brochura",
                            data: [{
                                file: [
                                    NayaResidenceBrochure1,
                                    NayaResidenceBrochure2,
                                    NayaResidenceBrochure3,
                                    NayaResidenceBrochure4,
                                    NayaResidenceBrochure5,
                                    NayaResidenceBrochure6,
                                    NayaResidenceBrochure7,
                                    NayaResidenceBrochure8,
                                    NayaResidenceBrochure9,
                                    NayaResidenceBrochure10,
                                    NayaResidenceBrochure11,
                                    NayaResidenceBrochure12,
                                    NayaResidenceBrochure13,
                                    NayaResidenceBrochure14,
                                    NayaResidenceBrochure15,
                                    NayaResidenceBrochure16,
                                    NayaResidenceBrochure17,
                                    NayaResidenceBrochure18,
                                    NayaResidenceBrochure19,
                                    NayaResidenceBrochure20,
                                    NayaResidenceBrochure21,
                                    NayaResidenceBrochure22,
                                    NayaResidenceBrochure23,
                                    NayaResidenceBrochure24,
                                    NayaResidenceBrochure25,
                                    NayaResidenceBrochure26,
                                    NayaResidenceBrochure27,
                                    NayaResidenceBrochure28,
                                    NayaResidenceBrochure29,
                                    NayaResidenceBrochure30,
                                    NayaResidenceBrochure31,
                                    NayaResidenceBrochure32,
                                    NayaResidenceBrochure33,
                                    NayaResidenceBrochure34
                                ]
                            }]
                        }
                    ]
                },
            ]
        }
    },
    {
        id: 'dxb_airport',
        checkIsInGroup: true,
        groupName: ['landmarks'],
        zoomLevel: null,
        coords: {lat: 25.253142380921556, lng: 55.3656575614154},
        polygonCoords: null,
        icon: MarkAirport,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false
        },
        content: null
    },
    {
        id: 'dubai_canal',
        checkIsInGroup: true,
        groupName: ['landmarks'],
        zoomLevel: 12,
        coords: {lat: 25.194975903997243, lng: 55.238651929645435},
        polygonCoords: null,
        icon: MarkDubaiCanal,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false
        },
        content: null
    },
    {
        id: 'jumeirah_beach',
        checkIsInGroup: true,
        groupName: ['landmarks'],
        zoomLevel: null,
        coords: {lat: 25.19391473532379, lng: 55.231646559007714},
        polygonCoords: null,
        icon: MarkJumeirahBeach,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false
        },
        content: null
    },
    {
        id: 'nakheel_marinas',
        checkIsInGroup: true,
        groupName: ['landmarks'],
        zoomLevel: null,
        coords: {lat: 25.29042993499695, lng: 55.296927953490645},
        polygonCoords: null,
        icon: MarkNakheelMarinas,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false
        },
        content: null
    },
    {
        id: 'the_view_at_the_palm',
        checkIsInGroup: true,
        groupName: ['landmarks'],
        zoomLevel: 11,
        coords: {lat: 25.112681170745817, lng: 55.13878120002334},
        polygonCoords: null,
        icon: MarkTheViewAtThePalm,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false
        },
        content: null
    },
    {
        id: 'palm_monorail',
        checkIsInGroup: true,
        groupName: ['landmarks'],
        zoomLevel: 12.5,
        coords: {lat: 25.100156205311755, lng: 55.15649614474076},
        polygonCoords: null,
        icon: MarkPalmMonorail,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false
        },
        content: null
    },
    {
        id: 'nakheel_sales',
        checkIsInGroup: true,
        groupName: ['landmarks'],
        zoomLevel: null,
        coords: {lat: 25.103536132427655, lng: 55.15330807676427},
        polygonCoords: null,
        icon: MarkNakheelSales,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false
        },
        content: null
    },
    {
        id: 'dwc_airport',
        checkIsInGroup: true,
        groupName: ['landmarks'],
        zoomLevel: null,
        coords: {lat: 24.885818090910437, lng: 55.158727077847054},
        polygonCoords: null,
        icon: MarkAirportDWC,
        activeIcon: null,
        clickable: {
            marker: true,
            polygon: false
        },
        content: null
    },

]


export const polylines = [
    {
        id: 'bus_station_1',
        path: bus_road_1,
        stops: bus_road_1_stops,
        icon: MarkBus1,
        color: "#EC5650",
    },
    {
        id: 'bus_station_1_2',
        path: bus_road_1_2,
        stops: [],
        icon: MarkBus1,
        color: "#EC5650",
    },
    {
        id: 'bus_station_2',
        path: bus_road_2,
        stops: bus_road_2_stops,
        icon: MarkBus2,
        color: "#49CD57",
    },
    {
        id: 'bus_station_3',
        path: bus_road_3,
        stops: bus_road_3_stops,
        icon: MarkBus3,
        color: "#F26E24",
    },
]
